export default {
    FETCH_EVENTS_REQUEST: 'FETCH_EVENTS_REQUEST',
    FETCH_EVENTS_SUCCESS: 'FETCH_EVENTS_SUCCESS',
    FETCH_EVENTS_FAILURE: 'FETCH_EVENTS_FAILURE',
    FETCH_TOTAL_EVENTS_REQUEST: 'FETCH_TOTAL_EVENTS_REQUEST',
    FETCH_TOTAL_EVENTS_SUCCESS: 'FETCH_TOTAL_EVENTS_SUCCESS',
    FETCH_TOTAL_EVENTS_FAILURE: 'FETCH_TOTAL_EVENTS_FAILURE',
    UPDATE_EVENT_STATE: 'UPDATE_EVENT_STATE',
    UPDATE_EVENT: 'UPDATE_EVENT',
    SET_ACTIVE_EVENT: 'SET_ACTIVE_EVENT',
    SET_DATA_EVENT: 'SET_DATA_EVENT',
    SET_ACTIVE_PAGE: 'SET_ACTIVE_PAGE',
    RESET_EVENT: 'RESET_EVENT',
    // AUTH
    USER_LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
    USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
    USER_LOGIN_FAILED: 'USER_LOGIN_FAILED',
    USER_LOGOUT: 'USER_LOGOUT',
    SHOW_POPUP_RESET_PASSWORD: 'SHOW_POPUP_RESET_PASSWORD',
    // PARENT EVENT TYPE
    FETCH_PARENT_EVENT_TYPE_REQUEST: 'FETCH_PARENT_EVENT_TYPE_REQUEST',
    FETCH_PARENT_EVENT_TYPE_SUCCESS: 'FETCH_PARENT_EVENT_TYPE_SUCCESS',
    FETCH_ANALYTICS_SUCCESS: 'FETCH_ANALYTICS_SUCCESS',
    UPDATE_EVENT_TYPE: 'UPDATE_EVENT_TYPE',
    // VIDEO
    SHOW_POPUP_VIDEO: 'SHOW_POPUP_VIDEO',
    // RISK ANALYTICS
    RISK_ANALYTICS_CHANGE_SHAPE: 'RISK_ANALYTICS_CHANGE_SHAPE',
    RISK_ANALYTICS_CHANGE_TYPE: 'RISK_ANALYTICS_CHANGE_TYPE',
};
