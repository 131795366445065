import React from 'react';

import { Grid, Container } from '@material-ui/core';

import { useHistory, useRouteMatch } from 'react-router-dom';
import error401 from 'assets/img/401.png';
import error404 from 'assets/img/404.png';
import error500 from 'assets/img/500.png';
import { genIdPLogoutUrl } from '../../utils/keycloak';
import { CommonButtonSubmit } from 'components/Common/InputCommon';

const Exception = () => {
    const history = useHistory();
    const match = useRouteMatch();

    const exception = [
        { errorCode: '404', errorImg: error404, errorDescription: 'Sorry, the page you visited does not exist' },
        { errorCode: '401', errorImg: error401, errorDescription: 'Sorry, you dont have access to this page' },
        { errorCode: '500', errorImg: error500, errorDescription: 'Sorry, the server is reporting an error' },
    ];

    let error = exception[2];

    if (match) {
        let params = new URLSearchParams(window.location.search);
        const type = params.get('type');
        error = exception.find(x => x.errorCode === type);

        if (error == null) {
            error = exception[0];
        }
    }

    const goBack = () => {
        if (error.errorCode === '401') {
            window.location = genIdPLogoutUrl('/');
        } else {
            history.push('/');
        }
    };

    return (
        <Container>
            <Grid className="exception grid-center" container spacing={3}>
                <Grid item xs={6} sm={6} md={6}>
                    <img className={'errorAvatar grid-center'} src={error.errorImg} alt={error.errorDescription} />
                </Grid>
                <Grid item xs={6} sm={6} md={6} style={{ marginTop: 75 }}>
                    <Grid item xs={6} sm={6} md={6} lg={10} xl={10}>
                        <h1 className={'errorTitle'}>{error.errorCode}</h1>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <h5 className={'errorDescription'}> {window?.t ? window.t(error.errorDescription) : ''}</h5>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                        <CommonButtonSubmit onClick={goBack}>{window?.t ? window.t('Back to Home') : ''}</CommonButtonSubmit>
                    </Grid>
                </Grid>
                <Grid />
            </Grid>
        </Container>
    );
};

export default Exception;
