import { getSelectedDashboardConfigJSON } from '../../utils';
import { WIDGET_KEY } from 'constants/report';
import { ALL_TIME, WIDGET_ID } from 'constants/index';
import clientCustomizeStorage from 'storage/ClientCustomizeStorage';

export const updateClientDashboardConfig = clientConfig => {
    clientCustomizeStorage.set(JSON.stringify(clientConfig));
};

export const updateSummaryWidgetConfig = configs => {
    if (configs) {
        const { date_range } = configs;
        const locationIds = configs[WIDGET_KEY.LOCATION_IDS] || false;
        const selectedConfig = getSelectedDashboardConfigJSON();
        const widgetConfig = (selectedConfig?.widgets || []).find(widget => widget.widget_id === WIDGET_ID.SUMMARY);
        const widgetParams = widgetConfig.widget_params;
        if (date_range) {
            widgetParams[WIDGET_KEY.DATE_RANGE] = date_range;
        }
        if (locationIds) {
            widgetParams[WIDGET_KEY.LOCATION_IDS] = locationIds;
        }
        updateClientDashboardConfig(selectedConfig);
    }
};

export const updateEventBarChartWidgetConfig = (widgetId, configs) => {
    if (widgetId && configs) {
        const { event_group_id, event_type_ids, group_by, date_range, location_ids } = configs;
        const selectedConfig = getSelectedDashboardConfigJSON();
        const widgetConfig = (selectedConfig?.widgets || []).find(widget => widget.widget_id === widgetId);
        const widgetParams = widgetConfig.widget_params;
        widgetParams[WIDGET_KEY.GROUP_TYPE] = event_group_id;
        widgetParams[WIDGET_KEY.EVENT_TYPES] = event_type_ids;
        widgetParams[WIDGET_KEY.GROUP_BY] = group_by;
        widgetParams[WIDGET_KEY.DATE_RANGE] = date_range;
        widgetParams[WIDGET_KEY.LOCATION_IDS] = location_ids;

        updateClientDashboardConfig(selectedConfig);
    }
};

export const updateDailySummaryWidgetConfig = configs => {
    if (configs) {
        const { data_type, date_range, event_group_ids } = configs;
        const selectedConfig = getSelectedDashboardConfigJSON();
        const widgetConfig = (selectedConfig?.widgets || []).find(widget => widget.widget_id === WIDGET_ID.DAILY_EVENT);
        const widgetParams = widgetConfig.widget_params;
        if (data_type) {
            widgetParams[WIDGET_KEY.DATA_TYPE] = data_type;
        }
        if (date_range) {
            widgetParams[WIDGET_KEY.DATE_RANGE] = date_range;
        }
        if (event_group_ids) {
            widgetParams[WIDGET_KEY.EVENT_GROUP_IDS] = event_group_ids;
        }
        widgetParams[WIDGET_KEY.LOCATION_IDS] = configs[WIDGET_KEY.LOCATION_IDS];
        updateClientDashboardConfig(selectedConfig);
    }
};

export const updateForReviewWidgetConfig = configs => {
    if (configs) {
        const { date_range, tag_ids, flagged, flagged_by, commented, location_ids } = configs;
        const selectedConfig = getSelectedDashboardConfigJSON();
        const widgetConfig = (selectedConfig?.widgets || []).find(widget => widget.widget_id === WIDGET_ID.FOR_REVIEW);
        const widgetParams = widgetConfig.widget_params;
        if (date_range !== ALL_TIME) widgetParams[WIDGET_KEY.DATE_RANGE] = date_range;
        widgetParams[WIDGET_KEY.TAGS] = tag_ids;
        widgetParams[WIDGET_KEY.FLAGGED] = flagged;
        widgetParams[WIDGET_KEY.FLAGGED_BY] = flagged_by;
        widgetParams[WIDGET_KEY.COMMENTED] = commented;
        widgetParams[WIDGET_KEY.LOCATION_IDS] = location_ids;
        updateClientDashboardConfig(selectedConfig);
    }
};
