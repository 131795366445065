import { DEFAULT_ALL_LOCATION_ID, DEFAULT_NAME_PERMISSION } from 'constants/index';
import _ from 'lodash';
import { parse } from 'qs';
import { axiosInstance } from 'services/httpClients';
import { getLang } from '../common/extensions';
import mixpanel from 'mixpanel-browser';
import { genIdPLogoutUrl } from './keycloak';
import axios from 'axios';
import userStorage from 'storage/UserStorage';
import dashboardStorage from 'storage/DashboardStorage';
import clientCustomizeStorage from 'storage/ClientCustomizeStorage';
import tokenStorage from 'storage/TokenStorage';
import jwt_decode from 'jwt-decode';
import { CAUSAL_RISK_FACTOR_CATEGORY_CODE } from 'constants/riskMitigation';

export const getUserDBConfig = userProfile => {
    return {
        language: _.get(userProfile, 'language', 'en'),
        distance: _.get(userProfile, 'distance'),
        time_format: _.get(userProfile, 'time_format', 'LT'),
        date_format: _.get(userProfile, 'date_format', 'MM-DD-YYYY'),
        playback_speed: _.get(userProfile, 'playback_speed', 1),
    };
};

export function getPageQuery(separator = '?') {
    return parse(window.location.href.split(separator)[1]);
}

export const getCurrentUser = () => {
    return JSON.parse(userStorage.get());
};

/**
 * Returns the sum of a and b
 * @param {string} searchParams
 * @param {string} path
 * @returns {boolean}
 */
export function compareClientId(searchParams, path) {
    const currentUser = getCurrentUser();
    const searchClientId = new URLSearchParams(searchParams);
    if (_.some(searchClientId) && DEFAULT_NAME_PERMISSION.EVENT_REVIEW === path)
        return currentUser?.client_id === Number(searchClientId.get('client_id'));
    return true;
}

export const deleteClientDashboardConfig = ({ name }) => {
    const encodedName = encodeURIComponent(name);
    return axiosInstance()
        .delete(`/api/v1/safety-dashboards/custom/${encodedName}`)
        .then(response => {
            if (response.status !== 204) {
                return new Error('delete Client Dashboard Configs fail');
            }
            return name;
        });
};

export const saveClientDashboardConfigs = ({ dashboard }) => {
    dashboard = _.omit(dashboard, ['isTemplate']);
    const encodedName = encodeURIComponent(dashboard?.name);
    return axiosInstance()
        .put(`/api/v1/safety-dashboards/custom/${encodedName}`, { dashboard })
        .then(response => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch(err => {
            return err.response;
        });
};

export const createClientDashboardConfig = ({ name, dashboard, source_template_save_as }) => {
    dashboard = _.omit(dashboard, ['isTemplate']);
    return axiosInstance()
        .post(`/api/v1/safety-dashboards/custom`, { name, dashboard, source_template_save_as })
        .then(response => {
            if (response.status === 201) {
                return response.data;
            }
        })
        .catch(err => {
            return err.response;
        });
};

export const getDashBoardConfigFromS3 = async url => {
    const response = await axios.get(url);
    return response.data;
};

export const sortAndUpdateDashboardToLocalStorage = dashboard => {
    sortDashboard(dashboard);
    dashboardStorage.set(JSON.stringify(dashboard));
};

const sortDashboard = dashboard => {
    // sort 'in place'
    dashboard.sort((d1, d2) => {
        if (d1.created_at && d2.created_at) {
            return d1.created_at < d2.created_at ? 1 : d1.created_at > d2.created_at ? -1 : 0;
        }

        // For existing dashboards which don't have `created_at`
        if (d1.created_at) {
            return -1;
        }
        if (d2.created_at) {
            return 1;
        }

        return d1.name.localeCompare(d2.name);
    });
};

export const getSafetyDashboards = async () => {
    return await axiosInstance().get(`/api/v1/safety-dashboards`);
};

export const getClientDashboardConfigs = async () => {
    const data = await getSafetyDashboards();
    const dashboardConfigUrls = data.data.dashboards;
    const templateConfigUrls = data.data.templates;
    const templateIds = new Set();
    const dashboardIds = new Set();

    const [templateConfigs, dashboardConfigs] = await Promise.all([
        Promise.all(
            templateConfigUrls.map(config => {
                return getDashBoardConfigFromS3(config?.signedUrl);
            })
        ),
        Promise.all(
            dashboardConfigUrls.map(config => {
                return getDashBoardConfigFromS3(config?.signedUrl);
            })
        ),
    ]);
    templateConfigs.forEach(dashboard => {
        dashboard['isTemplate'] = true;
        templateIds.add(dashboard.id);
    });
    dashboardConfigs.forEach(dashboard => {
        if (templateIds.has(dashboard.id)) {
            dashboard['isTemplate'] = true;
        } else {
            dashboard['isTemplate'] = false;
        }
        dashboardIds.add(dashboard.id);
    });

    let configs = [];
    if (dashboardConfigs && dashboardConfigs.length > 0) {
        configs = dashboardConfigs;
        templateConfigs.forEach(template => {
            if (!dashboardIds.has(template.id)) {
                configs.push(template);
            }
        });
    } else {
        configs = templateConfigs;
    }

    sortAndUpdateDashboardToLocalStorage(configs);
    return configs;
};

export const getSelectedDashboardConfigJSON = () => {
    const dashboard = clientCustomizeStorage.get();

    if (typeof dashboard === 'object') {
        const dashboardStr = JSON.stringify(dashboard);
        clientCustomizeStorage.set(dashboardStr);
        return dashboard;
    }
    return JSON.parse(dashboard);
};

export const sleep = milliseconds => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
};

export const isEmptyValues = value => {
    return (
        value === undefined ||
        value === null ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.toString().trim().length === 0) ||
        value.length === 0 ||
        value === 0 ||
        value === ''
    );
};

export const sortObject = obj => {
    return Object.keys(obj)
        .sort()
        .reduce((res, key) => {
            res[key] = obj[key];
            return res;
        }, {});
};

export const paramsToUrl = params => {
    const orderedParams = sortObject(params);
    return orderedParams
        ? Object.keys(orderedParams)
              .map(key => {
                  if (!isEmptyValues(orderedParams[key])) {
                      return `${encodeURIComponent(key)}=${encodeURIComponent(orderedParams[key])}`;
                  }
                  return null;
              })
              .filter(x => x !== null)
              .join('&')
        : '';
};

export const handleLogout = async () => {
    await axiosInstance().get(`/api/v1.1/logout`);
    const language = getLang();
    localStorage.clear();
    sessionStorage.clear();
    userStorage.set(JSON.stringify({ language }));
    try {
        mixpanel.reset();
    } catch (error) {}
    window.location = genIdPLogoutUrl('/');
};

export const downloadFileWithoutOpenNewTab = (downloadUrl, fileName) => {
    return fetch(downloadUrl, {
        method: 'GET',
    })
        .then(res => res.blob())
        .then(blob => {
            const originalUrl = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = originalUrl;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            // To remove the file in memory
            window.URL.revokeObjectURL(originalUrl);
        });
};

export const getCurrentUserRoles = () => {
    const token = tokenStorage.get();
    if (!token) return null;
    const decodedToken = jwt_decode(token);
    return decodedToken?.roles;
};

export const getLocationIds = (currentLocation, locations) => {
    if (!locations) return [];
    if (currentLocation === DEFAULT_ALL_LOCATION_ID) {
        return locations?.filter(item => item.id !== DEFAULT_ALL_LOCATION_ID)?.map(item => item.id);
    }
    return [currentLocation];
};

export function getClassNameFromCategoryCode(code) {
    switch (code) {
        case CAUSAL_RISK_FACTOR_CATEGORY_CODE.ERGONOMIC:
            return 'ergonomic';
        case CAUSAL_RISK_FACTOR_CATEGORY_CODE.STRUCK_BY:
            return 'struckBy';
        case CAUSAL_RISK_FACTOR_CATEGORY_CODE.SLIP_TRIP_FALL:
            return 'slipTripFall';
        case CAUSAL_RISK_FACTOR_CATEGORY_CODE.CAUGHT_IN_BETWEEN:
            return 'caughtInBetween';
        default:
            return '';
    }
}
