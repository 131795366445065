import { reducerHandler } from '../reduxHelper';
import { getComment, getEventTags, getTagCategory, getTagsList, getTracking, getVideoTracking, types } from './eventAction.js';
import actionTypes from 'constants/actionTypes.js';
import _ from 'lodash';

const initialAsyncState = {
    loaded: false,
    data: [],
    error: null,
};

const DEFAULT_SELECTED_EVENT_ID = 0;

const initialState = {
    comments: initialAsyncState,
    tracking: initialAsyncState,
    videoTracking: initialAsyncState,
    activeEvent: {},
    eventId: DEFAULT_SELECTED_EVENT_ID,
    page: 0,
    totalCount: 0,
    activePage: 1,
    isShowModalVideo: false,
    dataEvents: [],
    tags: initialAsyncState,
    selectedTags: initialAsyncState,
    tagCategory: initialAsyncState,
    events: initialAsyncState,
    isLoadingTotal: false,
};

export default function(state = initialState, action) {
    let updated = Object.assign({}, state);

    switch (action.type) {
        case getTagsList.REQUEST:
        case getTagsList.SUCCESS:
        case getTagsList.FAILURE:
            return {
                ...state,
                tags: reducerHandler(state.tags, action, getTagsList),
            };
        case getTagCategory.REQUEST:
        case getTagCategory.SUCCESS:
        case getTagCategory.FAILURE:
            return {
                ...state,
                tagCategory: reducerHandler(state.tagCategory, action, getTagCategory),
            };
        case getEventTags.REQUEST:
        case getEventTags.SUCCESS:
        case getEventTags.FAILURE:
            return {
                ...state,
                selectedTags: reducerHandler(state.selectedTags, action, getEventTags),
            };
        case types.RESET_TAGS:
            return {
                ...state,
                selectedTags: initialAsyncState,
            };
        case types.POST_EVENT_TAGS: {
            const newCopy = state.selectedTags.data ? [...state.selectedTags.data] : [];
            newCopy.unshift(action.data);
            return {
                ...state,
                selectedTags: {
                    ...state.selectedTags,
                    data: newCopy,
                },
            };
        }
        case types.POST_TAG_CATEGORY: {
            const newCopy = state.tagCategory.data ? [...state.tagCategory.data] : [];
            newCopy.unshift(action.data);
            return {
                ...state,
                tagCategory: {
                    ...state.tagCategory,
                    data: newCopy,
                },
            };
        }

        case types.DELETE_EVENT_TAGS: {
            const oldCopy = state.selectedTags.data ? [...state.selectedTags.data] : [];
            return {
                ...state,
                selectedTags: {
                    ...state.selectedTags,
                    data: oldCopy.filter(item => item.id !== action.data),
                },
            };
        }
        case getComment.REQUEST:
        case getComment.SUCCESS:
        case getComment.FAILURE:
            return {
                ...state,
                comments: reducerHandler(state.comments, action, getComment),
            };
        case types.RESET_COMMENT:
            return {
                ...state,
                comments: initialAsyncState,
            };
        case getVideoTracking.REQUEST:
        case getVideoTracking.SUCCESS:
        case getVideoTracking.FAILURE:
            return {
                ...state,
                videoTracking: reducerHandler(state.videoTracking, action, getVideoTracking),
            };
        case getTracking.REQUEST:
        case getTracking.SUCCESS:
        case getTracking.FAILURE:
            return {
                ...state,
                tracking: reducerHandler(state.tracking, action, getTracking),
            };
        case types.REJECT_EVENT:
            const dataEventsCopy = [...state.dataEvents];
            return {
                ...state,
                dataEvents: dataEventsCopy.filter(item => item.id !== action.data),
            };
        case actionTypes.FETCH_EVENTS_REQUEST:
            const isLoaded = action.isLoadNextPage ? state.events.loaded : false;
            return {
                ...state,
                isLoading: true,
                events: {
                    ...state.events,
                    loaded: isLoaded,
                },
            };
        case actionTypes.FETCH_EVENTS_SUCCESS: {
            const totalCount = action?.events?.totalCount || 0;
            const concatEvents = action?.events.isLoadNextPage
                ? [...state.dataEvents, ...action.events.data]
                : action.events.data;

            return {
                ...state,
                isLoading: false,
                events: {
                    ...action.events,
                    totalCount: totalCount > 0 ? totalCount : state?.events?.totalCount, // Fix case response of load total event before this api
                    loaded: true,
                },
                error: null,
                dataEvents: concatEvents,
                eventId: concatEvents.length > 0 ? state.eventId : DEFAULT_SELECTED_EVENT_ID,
            };
        }
        case actionTypes.FETCH_EVENTS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action?.error,
                events: {
                    ...state.events,
                    loaded: true,
                },
            };
        case actionTypes.FETCH_TOTAL_EVENTS_REQUEST:
            return {
                ...state,
                isLoadingTotal: true,
            };
        case actionTypes.FETCH_TOTAL_EVENTS_SUCCESS:
            return {
                ...state,
                isLoadingTotal: false,
                events: { ...state.events, totalCount: action.totalCount },
                error: null,
            };

        case actionTypes.FETCH_TOTAL_EVENTS_FAILURE:
            return {
                ...state,
                isLoadingTotal: false,
                error: action?.error,
            };
        case types.DELETE_COMMENT: {
            const oldCopy = [...state.comments.data.data];
            return {
                ...state,
                comments: {
                    ...state.comments,
                    data: { ...state.comments.data, data: oldCopy.filter(item => item.id !== action.data) },
                },
            };
        }
        case types.POST_COMMENT: {
            const newCopy = [...state.comments.data.data];
            newCopy.unshift(action.data);
            return {
                ...state,
                comments: {
                    ...state.comments,
                    data: { ...state.comments.data, data: newCopy },
                },
            };
        }
        case types.UPDATE_ACTIVE_EVENT:
            const ActiveEventData = _.cloneDeep(state.activeEvent.data);
            const newActiveEventData = Object.assign(ActiveEventData, action.data);
            const newDataEvents = state.dataEvents?.map((v, i) => {
                if (i === state.activeEvent.index) {
                    v = newActiveEventData;
                }
                return v;
            });
            return {
                ...state,
                activeEvent: {
                    ...state.activeEvent,
                    data: newActiveEventData,
                },
                dataEvents: newDataEvents,
            };
        case actionTypes.SET_ACTIVE_EVENT:
            return {
                ...state,
                activeEvent: action.event,
                eventId: _.get(action.event, 'data.id'),
            };
        case actionTypes.SET_DATA_EVENT:
            return { ...state, dataEvents: action.event };
        case actionTypes.RESET_EVENT: {
            const reset = _.cloneDeep(initialState);
            const tagCategory = { ...state.tagCategory };
            return { ...reset, tagCategory };
        }
        case actionTypes.UPDATE_EVENT_STATE:
            const eventUpdated = updated.data.find(event => event.id === action.id);
            const index = updated.data.findIndex(event => event.id === action.id);
            eventUpdated.severity_id = action.severity_id;
            eventUpdated.state_id = action.state_id;
            eventUpdated.status = action.status;
            updated.data[index] = eventUpdated;
            return { ...updated };
        case actionTypes.UPDATE_EVENT:
            const eventToUpdated = updated.data.find(event => event.id === action.id);
            const indexToUpdated = updated.data.findIndex(event => event.id === action.id);
            eventToUpdated.comments = action.comments;
            eventToUpdated.event_type = action.event_type;
            eventToUpdated.event_type_parent = action.event_type_parent;
            updated.data[indexToUpdated] = eventToUpdated;
            return { ...updated };
        case actionTypes.UPDATE_EVENT_TYPE:
            const eUpdated = updated.data.find(event => event.id === action.id);
            const indexUpdated = updated.data.findIndex(event => event.id === action.id);
            eUpdated.event_type = action.event_type;
            eUpdated.event_type_id = action.event_type_id;
            eUpdated.event_type_parent = action.event_type_parent;
            eUpdated.event_type_parent_id = action.event_type_parent_id;
            updated.data[indexUpdated] = eUpdated;
            return { ...updated };
        case actionTypes.SET_ACTIVE_PAGE:
            updated.activePage = action.activePage;
            return { ...updated };
        case actionTypes.SHOW_POPUP_VIDEO: {
            let newState = { ...state, modalBoxId: action.data.boxId, modalFrame: action.data.frame };
            return newState;
        }
        default:
            return state;
    }
}
