// import translationEN from "./en/translation.json"
// import translationJP from "./jp/translation.json"
import Backend from 'i18next-http-backend';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import I18NextChainedBackend from "i18next-chained-backend";
import resourcesToBackend from "i18next-resources-to-backend";
import { getLang } from 'common/extensions';

i18n
    .use(I18NextChainedBackend)
    .use(initReactI18next)
    .init({
        backend: {
            backends: [
                Backend,
                resourcesToBackend((lng, ns, clb) => {
                    import(`locales/${lng}/translation.json`)
                        .then((resources) => {
                            clb(null, resources)
                        })
                        .catch(clb)
                })
            ],
            backendOptions: [{
                loadPath: 'locales/{{lng}}/translation.json'
            }]
        },
        fallbackLng: getLang(),

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        react: {
            useSuspense: false
        }
    });


export default i18n;
