class CurrentClientStorage {
    key = 'current_client';

    get() {
        return localStorage.getItem(this.key);
    }

    set(value) {
        localStorage.setItem(this.key, value);
    }

    del() {
        localStorage.removeItem(this.key);
    }
}
const currentClientStorage = new CurrentClientStorage();

export default currentClientStorage;
