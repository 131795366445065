import { DEFAULT_NAME_PERMISSION } from 'constants/index';
import actionTypes from 'constants/actionTypes.js';
import { types } from './authAction';

const initialState = {
    loading: false,
    error: '',
    token: null,
    isShowResetPassword: false,
    isAuthenticated: false,
    permission: [DEFAULT_NAME_PERMISSION.USER_SETTING],
};

export default function(state = initialState, action) {
    let updated = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.USER_LOGIN_REQUEST:
            updated.loading = true;
            updated.error = '';
            return updated;
        case actionTypes.USER_LOGIN_SUCCESS:
            updated.loading = false;
            updated.error = '';
            updated.token = action.token;
            return updated;
        case actionTypes.USER_LOGIN_FAILED:
            updated.loading = false;
            updated.error = 'Username or Password is invalid';
            return updated;
        case actionTypes.SHOW_POPUP_RESET_PASSWORD: {
            let newState = { ...state, isShowResetPassword: action.data };
            return newState;
        }
        case types.VERIFY_PERMISSION:
            return {
                ...state,
                isAuthenticated: true,
                permission: [...state.permission, ...action.data].sort((a, b) => {
                    if (a < b) {
                        return -1;
                    }
                    if (a > b) {
                        return 1;
                    }
                    return 0;
                }),
            };
        default:
            return state;
    }
}
