import { actionCreator } from '../reduxHelper';

export const types = {
    DELETE_COMMENT: 'DELETE_COMMENT',
    POST_COMMENT: 'POST_COMMENT',
    REJECT_EVENT: 'REJECT_EVENT',
    UPDATE_ACTIVE_EVENT: 'UPDATE_ACTIVE_EVENT',
    RESET_COMMENT: 'RESET_COMMENT',
    RESET_TAGS: 'RESET_TAGS',
    POST_EVENT_TAGS: 'POST_EVENT_TAGS',
    POST_TAG_CATEGORY: 'POST_TAG_CATEGORY',
    DELETE_TAG_CATEGORY: 'DELETE_TAG_CATEGORY',
    DELETE_EVENT_TAGS: 'DELETE_EVENT_TAGS',
};
export const getComment = actionCreator('GET_ACTIVE_EVENT_COMMENT');
export const getVideoTracking = actionCreator('GET_VIDEO_TRACKING');
export const getTracking = actionCreator('GET_TRACKING');
export const getTagsList = actionCreator('GET_TAGS_LIST');
export const getEventTags = actionCreator('GET_EVENT_TAG');
export const getTagCategory = actionCreator('GET_TAG_CATEGORY');

export const postEventToList = data => {
    return dispatch =>
        dispatch({
            type: types.POST_EVENT_TAGS,
            data,
        });
};

export const postTagCategoryToList = data => {
    return dispatch =>
        dispatch({
            type: types.POST_TAG_CATEGORY,
            data,
        });
};

export const deleteEvent = data => {
    return dispatch =>
        dispatch({
            type: types.DELETE_EVENT_TAGS,
            data,
        });
};

export const deleteComment = data => {
    return dispatch =>
        dispatch({
            type: types.DELETE_COMMENT,
            data,
        });
};

export const rejectEvent = data => ({
    type: types.REJECT_EVENT,
    data,
});

export const postComment = data => {
    return dispatch =>
        dispatch({
            type: types.POST_COMMENT,
            data,
        });
};

export const resetComment = data => {
    return dispatch =>
        dispatch({
            type: types.RESET_COMMENT,
            data,
        });
};

export const resetTags = data => {
    return dispatch =>
        dispatch({
            type: types.RESET_TAGS,
            data,
        });
};

export const updateActiveEvent = data => {
    return dispatch =>
        dispatch({
            type: types.UPDATE_ACTIVE_EVENT,
            data,
        });
};
