import {
    types,
    getEventSummaryVISUALB,
    getEventSummaryVISUALC,
    getEventSummaryVISUALD,
    getDailyEvent,
    getObserList,
    getEventTypeSummary,
} from './awareAction.js';
import { reducerHandler } from '../reduxHelper';
import _ from 'lodash';

const initialAsyncState = {
    isLoading: false,
    loaded: false,
    data: null,
    error: null,
};

const initialState = {
    customer: [],
    observationList: initialAsyncState,
    selectMonth: null,
    chart_type: [{ id: 1, name: 'Bar chart' }, { id: 2, name: 'Area chart' }],
    chart_selected: {
        VisualizationC: {
            id: 2,
            name: 'Area chart',
        },
        VisualizationB: {
            id: 2,
            name: 'Area chart',
        },
        VisualizationD: {
            id: 2,
            name: 'Area chart',
        },
        daily_event: {
            id: 1,
            name: 'Bar chart',
        },
    },
    summary_visualB: initialAsyncState,
    summary_visualC: initialAsyncState,
    summary_visualD: initialAsyncState,
    daily_event: initialAsyncState,
    event_type_summary: initialAsyncState,
    custom_dashboard: {},
    event_clips: [],
    observation_type: [{ id: 1, name: 'Observation List' }, { id: 2, name: 'Observation card' }],
    observation_selected: { id: 2, name: 'Observation card' },
    user_editable_dashboard: {},
    summaryParams: {
        groupId: null,
        widgetDateRange: '',
    },
    refreshWidgetsNumber: 0,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case types.GET_CUSTOMER_SUCCESS:
            return {
                ...state,
                customer: action.data.data,
            };
        case types.SWITCH_CHART:
            return {
                ...state,
                chart_selected: Object.assign(_.cloneDeep(state.chart_selected), action.data),
            };
        case types.SWITCH_OBSERVATION:
            return {
                ...state,
                observation_selected: action.data,
            };
        case getEventSummaryVISUALB.REQUEST:
        case getEventSummaryVISUALB.SUCCESS:
        case getEventSummaryVISUALB.FAILURE:
            return {
                ...state,
                summary_visualB: reducerHandler(state.summary_visualB, action, getEventSummaryVISUALB),
            };
        case getEventSummaryVISUALC.REQUEST:
        case getEventSummaryVISUALC.SUCCESS:
        case getEventSummaryVISUALC.FAILURE:
            return {
                ...state,
                summary_visualC: reducerHandler(state.summary_visualC, action, getEventSummaryVISUALC),
            };
        case getEventSummaryVISUALD.REQUEST:
        case getEventSummaryVISUALD.SUCCESS:
        case getEventSummaryVISUALD.FAILURE:
            return {
                ...state,
                summary_visualD: reducerHandler(state.summary_visualD, action, getEventSummaryVISUALD),
            };
        case getDailyEvent.REQUEST:
        case getDailyEvent.SUCCESS:
        case getDailyEvent.FAILURE:
            return {
                ...state,
                daily_event: reducerHandler(state.daily_event, action, getDailyEvent),
            };
        case types.CUSTOM_DASHBOARD: {
            if (action.data?.reset) {
                delete action.data.reset;
                return {
                    ...state,
                    custom_dashboard: action.data,
                };
            } else {
                return {
                    ...state,
                    custom_dashboard: Object.assign(state.custom_dashboard, action.data),
                };
            }
        }
        case getObserList.REQUEST:
        case getObserList.FAILURE:
            return {
                ...state,
                observationList: reducerHandler(state.observationList, action, getObserList),
            };
        case getObserList.SUCCESS: {
            const totalCount = action?.events?.totalCount || 0;
            return {
                ...state,
                observationList: {
                    isLoading: false,
                    loaded: true,
                    data: {
                        ...action.data,
                        totalCount: totalCount > 0 ? totalCount : state?.observationList?.data?.totalCount,
                    },
                    error: null,
                },
            };
        }
        case getEventTypeSummary.REQUEST:
        case getEventTypeSummary.SUCCESS:
        case getEventTypeSummary.FAILURE:
            return {
                ...state,
                event_type_summary: reducerHandler(state.event_type_summary, action, getEventTypeSummary),
            };
        case types.RESET_CUSTOM_DASHBOARD: {
            const Dashboard = state.custom_dashboard['Dashboard'];
            return {
                ...state,
                custom_dashboard: { Dashboard },
            };
        }
        case types.RESET_OBSERVATIONLIST:
            return {
                ...state,
                observationList: initialAsyncState,
            };
        case types.USER_EDITABLE_DASHBOARD:
            return {
                ...state,
                user_editable_dashboard: action.data,
            };
        case types.GET_SUMMARY_PARAMS:
            return {
                ...state,
                summaryParams: action.data,
            };
        case types.REFRESH_WIDGETS:
            return {
                ...state,
                refreshWidgetsNumber: state.refreshWidgetsNumber + 1,
            };
        default:
            return state;
    }
}
