class TokenStorage {
    key = 'token';

    get() {
        return localStorage.getItem(this.key);
    }

    set(value) {
        localStorage.setItem(this.key, value);
    }

    del() {
        localStorage.removeItem(this.key);
    }

    parseJwt(token) {
        try {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace('-', '+').replace('_', '/');
            return JSON.parse(window.atob(base64));
        } catch (err) {
            return {};
        }
    }

    /** Get token.iss */
    getTokenIssuedBy(value) {
        const token = value ? value : this.get();
        if (token) {
            const data = this.parseJwt(token);
            return data.iss;
        }

        return undefined;
    }
}

const tokenStorage = new TokenStorage();

export default tokenStorage;
