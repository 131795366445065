export const actionCreator = action => {
    const values = ['SUCCESS', 'FAILURE', 'REQUEST'];
    const types = values.reduce((acc, value) => {
        const type = `${action}_${value}`;
        acc[value] = type;
        acc[value.toLowerCase()] = data => ({
            type,
            data,
        });
        return acc;
    }, {});
    return types;
};

export const reducerHandler = (state, action, actionHandler) => {
    switch (action.type) {
        case actionHandler.REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case actionHandler.SUCCESS:
            return {
                ...state,
                isLoading: false,
                loaded: true,
                data: action.data,
                error: null,
            };
        case actionHandler.FAILURE:
            return {
                ...state,
                isLoading: false,
                loaded: true,
                error: action.data,
                data: null,
            };
        default:
            return state;
    }
};
