import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import { getObserListAPI, getEventSummaryAPI, getEventTypeSummaryAPI } from 'services/awareApis';
import {
    getEventSummaryVISUALB,
    getEventSummaryVISUALC,
    getEventSummaryVISUALD,
    getDailyEvent,
    getObserList,
    getEventTypeSummary,
} from './awareAction.js';
import moment from 'common/momentExtensions';
import _ from 'lodash';
import { exception } from 'constants/errorCode';

export default function* AwareSagas() {
    yield all([
        takeLatest(getObserList.REQUEST, watchGetObserList),
        takeLatest(getEventSummaryVISUALB.REQUEST, watchGetSummaryVISUALB),
        takeLatest(getEventSummaryVISUALC.REQUEST, watchGetSummaryVISUALC),
        takeLatest(getEventSummaryVISUALD.REQUEST, watchGetSummaryVISUALD),
        takeLatest(getDailyEvent.REQUEST, watchGetDailyEvent),
        takeLatest(getEventTypeSummary.REQUEST, watchGetEventTypeSummary),
    ]);
}

export function* watchGetObserList(data) {
    try {
        const getObservationList = state => state.aware.observationList;
        const observationList = yield select(getObservationList);
        let res = yield call(getObserListAPI, data.data);
        if (res && res.status === 200) {
            if (observationList?.data?.items && observationList?.data?.items.length) {
                res.data.items = _.uniqBy(observationList.data.items.concat(res.data.items), 'id');
            }
            yield put(getObserList.success(res.data || {}));
        } else {
            throw res;
        }
    } catch (error) {
        yield put(getObserList.failure(window.t(exception['500'])));
    }
}

export function* watchGetSummaryVISUALB(data) {
    try {
        if (data && data.data) {
            const response = yield call(getEventSummaryAPI, data.data);
            if (response && response.status === 200) {
                const lastStick = [];
                Object.keys(response.data.data).map(stick => {
                    const convertDate = moment(stick).YYYY_MM_DD();
                    return lastStick.push({ Events: response.data.data[stick], date: convertDate });
                });
                yield put(getEventSummaryVISUALB.success(lastStick));
            } else {
                throw response;
            }
        }
    } catch (error) {
        yield put(getEventSummaryVISUALB.failure(window.t(exception['500'])));
    }
}
export function* watchGetSummaryVISUALC(data) {
    try {
        if (data && data.data) {
            const response = yield call(getEventSummaryAPI, data.data);
            if (response && response.status === 200) {
                const lastStick = [];
                Object.keys(response.data.data).map(stick => {
                    const convertDate = moment(stick).YYYY_MM_DD();
                    return lastStick.push({ Events: response.data.data[stick], date: convertDate });
                });
                yield put(getEventSummaryVISUALC.success(lastStick));
            } else {
                throw response;
            }
        }
    } catch (error) {
        yield put(getEventSummaryVISUALC.failure(window.t(exception['500'])));
    }
}

export function* watchGetSummaryVISUALD(data) {
    try {
        if (data && data.data) {
            const response = yield call(getEventSummaryAPI, data.data);
            if (response && response.status === 200) {
                const lastStick = [];
                Object.keys(response.data.data).map(stick => {
                    const convertDate = moment(stick).YYYY_MM_DD();
                    return lastStick.push({ Events: response.data.data[stick], date: convertDate });
                });
                yield put(getEventSummaryVISUALD.success(lastStick));
            } else {
                throw response;
            }
        }
    } catch (error) {
        yield put(getEventSummaryVISUALD.failure(window.t(exception['500'])));
    }
}

export function* watchGetDailyEvent(data) {
    try {
        if (data && data.data) {
            const response = yield call(getEventSummaryAPI, data.data);
            if (response && response.status === 200) {
                const lastStick = [];
                Object.keys(response.data.data).map(stick => {
                    const convertDate = moment(stick).YYYY_MM_DD();
                    return lastStick.push({ Events: response.data.data[stick], date: convertDate });
                });
                yield put(getDailyEvent.success(lastStick));
            } else {
                throw response;
            }
        }
    } catch (error) {
        yield put(getDailyEvent.failure(window.t(exception['500'])));
    }
}

export function* watchGetEventTypeSummary(data) {
    try {
        if (data && data.data) {
            const response = yield call(getEventTypeSummaryAPI, data.data);
            if (response && response.status === 200) {
                yield put(getEventTypeSummary.success(response.data));
            } else {
                throw response;
            }
        }
    } catch (error) {
        yield put(getEventTypeSummary.failure(window.t(exception['500'])));
    }
}
