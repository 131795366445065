/**
 * Returns a hash code for a string.
 * (Compatible to Java's String.hashCode())
 *
 * The hash code for a string object is computed as
 *     s[0]*31^(n-1) + s[1]*31^(n-2) + ... + s[n-1]
 * using number arithmetic, where s[i] is the i th character
 * of the given string, n is the length of the string,
 * and ^ indicates exponentiation.
 *
 * @param {string | number} input a string | number
 * @return {string} a hash code value for the given string.
 */
export const hashCode = input => {
    if (input === undefined || input === null) {
        return '';
    }

    let h = 0;
    const s = input.toString();

    if (s.length > 0) {
        let i = 0;
        while (i < s.length) {
            h = ((h << 5) - h + s.charCodeAt(i++)) | 0;
        }
    }

    return h.toString();
};

export const hashClientId = clientId => {
    return hashCode(`client-${clientId}`);
};
