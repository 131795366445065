import actionTypes from "constants/actionTypes.js";
import { SAFETY_CENTER, RISK_ANALYTIC } from "constants/index.js"

const initialState = {
    loading: false,
    shape: SAFETY_CENTER.SHAPES.LAST_7_DAYS,
    data: [
        // {
        //     name: "Hazards",
        //     status: "increase",
        //     percent: "50%",
        //     total: 17,
        //     dataInfo: []
        // },
        // {
        //     name: "Safety Violations",
        //     status: "decrease",
        //     percent: "50%",
        //     total: 7,
        //     dataInfo: []
        // },
        // {
        //     name: "Near misses",
        //     status: "no alternation",
        //     percent: "",
        //     total: 3,
        //     dataInfo: []
        // },
        // {
        //     name: "Injuries",
        //     status: "decrease",
        //     percent: "100%",
        //     total: 0,
        //     dataInfo: []
        // }
    ],
    riskAnalyticShape: RISK_ANALYTIC.SHAPES.LIST,
    riskAnalyticType: RISK_ANALYTIC.TYPES.MAP,
}

export default function (state = initialState, action) {
    let updated = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.FETCH_PARENT_EVENT_TYPE_REQUEST:
            updated.loading = true;
            return updated;
        case actionTypes.FETCH_PARENT_EVENT_TYPE_SUCCESS:
            updated.loading = false;
            updated.shape = action.shape;
            updated.data = action.data;
            return updated;
        case actionTypes.FETCH_ANALYTICS_SUCCESS:
            updated.data = action.data;
            return updated;
        case actionTypes.RISK_ANALYTICS_CHANGE_SHAPE:
            updated.riskAnalyticShape = action.riskAnalyticShape;
            return updated
        case actionTypes.RISK_ANALYTICS_CHANGE_TYPE:
            updated.riskAnalyticType = action.riskAnalyticType;
            return updated
        default:
            return state;
    }
}