import moment from "moment";
import { getLang } from "common/extensions";
import langCodes from "constants/langCodes";

// var locale = window.navigator.userLanguage || window.navigator.language;

// moment.locale(`${locale}`);
const language = getLang();
const subString = String(langCodes[language]);
const locales = language !== "en" ? import(`moment/locale/${language}`) : null;

if (locales) {
    moment.updateLocale(subString, locales);
}

moment.prototype.YYYYMMDD = function () {
    return this.format("YYYYMMDD")
}

moment.prototype.YYYYMMDDHHMMSS = function () {
    return this.format("YYYYMMDDHHmmss")
}

moment.prototype.YYYY_MM_DD = function () {
    return this.format("YYYY/MM/DD")
}

// moment().format('LT');   // 4:00 PM
// moment().format('LTS');  // 4:00:28 PM
// moment().format('L');    // 03/19/2021
// moment().format('l');    // 3/19/2021
// moment().format('LL');   // March 19, 2021
// moment().format('ll');   // Mar 19, 2021
// moment().format('LLL');  // March 19, 2021 4:00 PM
// moment().format('lll');  // Mar 19, 2021 4:00 PM
// moment().format('LLLL'); // Friday, March 19, 2021 4:00 PM
// moment().format('llll'); // Fri, Mar 19, 2021 4:00 PM

moment.prototype.MM_DD = function () {
    return this.format("MM-DD")
}

moment.prototype.HH_MM_SS = function () {
    return this.format("HH:mm:ss")
}

export default moment;