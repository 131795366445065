import { toast } from 'react-toastify';
import React from 'react';
import { TOAST_DURATION_MS } from 'constants/index';

export const message = {
    error: (title = "Can't customize now", body = 'Please try again later') => {
        return toast.info(
            <div>
                <span className="toast-title toast-bold">{title}</span>
                <br /> <span className="toast-message">{body}</span>
            </div>,
            { bodyClassName: 'toast-box toast-error', autoClose: TOAST_DURATION_MS }
        );
    },
    success: (title = 'Success', body) => {
        return toast.success(
            <div>
                <span className="toast-title toast-bold">{title}</span>
                <br /> <span className="toast-message">{body}</span>
            </div>,
            { bodyClassName: 'toast-box toast-success', autoClose: TOAST_DURATION_MS }
        );
    },
    copied: (title, cb) => {
        toast.info(title, { onClose: () => cb, autoClose: TOAST_DURATION_MS });
    },
};
