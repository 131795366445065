import { DEFAULT_NAME_PERMISSION } from 'constants/index';
import _ from 'lodash';
import moment from 'common/momentExtensions';

/**
 * Returns the sum of a and b
 * @param {number | string} value
 * @returns {number}
 */
export function countDigit(value) {
    if ((_.isNumber(value) || _.isString(value)) && !isNaN(value) && isFinite(value)) return value.toString().length;
    return false;
}

/**
 * Returns date time format
 * @param {number | string} val
 * @param {boolean | 'toDate'} format
 * @param {string} longFormat
 * @returns {number | string}
 */
export const convertDateTime = (val, format, longFormat = 'L') => {
    if (format === 'toDate') {
        return moment.utc(val, 'YYYY-MM-DD').toDate();
    }
    if (countDigit(val) && countDigit(val) < 5) {
        return val;
    }
    if (_.isBoolean(format)) {
        return moment.utc(val, 'YYYY-MM-DD').format(longFormat.replace(/YYYY/g, 'YY'));
    }
    if (val) return moment.utc(val, 'YYYY-MM-DD').format('MM/DD');
    return '';
};

export const convertPathname = value => {
    switch (value) {
        case 0:
        case DEFAULT_NAME_PERMISSION.DASHBOARD:
            return '/';
        case 1:
        case DEFAULT_NAME_PERMISSION.SAFETY_CENTER:
            return '/safety-center';
        case 2:
        case DEFAULT_NAME_PERMISSION.EVENT_REVIEW:
            return '/event-review';
        case 3:
        case DEFAULT_NAME_PERMISSION.SOLUTIONS:
            return '/solutions';
        case 4:
        case DEFAULT_NAME_PERMISSION.RISK_ANALYTIC:
            return '/analytics';
        case 5:
        case DEFAULT_NAME_PERMISSION.VIDEO_REVIEW:
            return '/video-review';

        case DEFAULT_NAME_PERMISSION.USER_SETTING:
            return '/user/settings';
        case '/':
            return 0;
        case '/safety-center':
            return 1;
        case '/event-review':
        case '/event/:event_id':
            return 2;
        case '/solutions':
            return 3;
        case '/analytics':
            return 4;
        case '/video-review':
            return 5;

        default:
            break;
    }
    if (value.indexOf('/event/') === 0) {
        return 1;
    }
    return false;
};

export const verifyPermission = value => {
    switch (value) {
        case DEFAULT_NAME_PERMISSION.DASHBOARD:
            return '/';
        case DEFAULT_NAME_PERMISSION.SAFETY_CENTER:
            return '/safety-center';
        case DEFAULT_NAME_PERMISSION.EVENT_REVIEW:
            return '/event-review';
        case DEFAULT_NAME_PERMISSION.RISK_ANALYTIC:
            return '/analytics';
        case DEFAULT_NAME_PERMISSION.VIDEO_REVIEW:
            return '/video-review';
        case DEFAULT_NAME_PERMISSION.USER_SETTING:
            return '/user/settings';
        case '/':
            return DEFAULT_NAME_PERMISSION.DASHBOARD;
        case '/safety-center':
            return DEFAULT_NAME_PERMISSION.SAFETY_CENTER;
        case '/event-review':
        case '/event/:event_id':
            return DEFAULT_NAME_PERMISSION.EVENT_REVIEW;
        case '/analytics':
            return DEFAULT_NAME_PERMISSION.RISK_ANALYTIC;
        case '/video-review':
            return DEFAULT_NAME_PERMISSION.VIDEO_REVIEW;
        case '/user/settings':
            return DEFAULT_NAME_PERMISSION.USER_SETTING;
        default:
            break;
    }
    if (value.indexOf('/event/') === 0) {
        return DEFAULT_NAME_PERMISSION.EVENT_REVIEW;
    }
    return false;
};
