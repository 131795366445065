import { actionCreator } from '../reduxHelper';
import {
    updateDailySummaryWidgetConfig,
    updateEventBarChartWidgetConfig,
    updateForReviewWidgetConfig,
    updateSummaryWidgetConfig,
} from 'redux/aware/utils';
import { COMPONENT_NAME, WIDGET_ID } from 'constants/index';
import clientCustomizeStorage from 'storage/ClientCustomizeStorage';

export const types = {
    SWITCH_CHART: 'SWITCH_CHART',
    SWITCH_OBSERVATION: 'SWITCH_OBSERVATION',
    CUSTOM_DASHBOARD: 'CUSTOM_DASHBOARD',
    CHANGE_LOCATION: 'CHANGE_LOCATION',
    RESET_OBSERVATIONLIST: 'RESET_OBSERVATIONLIST',
    RESET_CUSTOM_DASHBOARD: 'RESET_CUSTOM_DASHBOARD',
    USER_EDITABLE_DASHBOARD: 'USER_EDITABLE_DASHBOARD',
    GET_SUMMARY_PARAMS: 'GET_SUMMARY_PARAMS',
    REFRESH_WIDGETS: 'REFRESH_WIDGETS',
};

export const switchChart = data => {
    return dispatch =>
        dispatch({
            type: types.SWITCH_CHART,
            data,
        });
};

export const customDashboard = data => {
    if (data[COMPONENT_NAME.Score]) {
        updateSummaryWidgetConfig(data[COMPONENT_NAME.Score]);
    }
    if (data[COMPONENT_NAME.VisualizationB]) {
        updateEventBarChartWidgetConfig(WIDGET_ID.EVENT1, data[COMPONENT_NAME.VisualizationB]);
    }
    if (data[COMPONENT_NAME.VisualizationC]) {
        updateEventBarChartWidgetConfig(WIDGET_ID.EVENT2, data[COMPONENT_NAME.VisualizationC]);
    }
    if (data[COMPONENT_NAME.VisualizationD]) {
        updateEventBarChartWidgetConfig(WIDGET_ID.EVENT3, data[COMPONENT_NAME.VisualizationD]);
    }
    if (data[COMPONENT_NAME.daily_event]) {
        updateDailySummaryWidgetConfig(data[COMPONENT_NAME.daily_event]);
    }
    if (data[COMPONENT_NAME.ObservationCard]) {
        updateForReviewWidgetConfig(data[COMPONENT_NAME.ObservationCard]);
    }

    const userEditableDashboard = JSON.parse(clientCustomizeStorage.get());

    return dispatch => {
        dispatch({
            type: types.USER_EDITABLE_DASHBOARD,
            data: userEditableDashboard,
        });
        dispatch({
            type: types.CUSTOM_DASHBOARD,
            data,
        });
    };
};

export const updateUserEditableDashboard = data => {
    return dispatch =>
        dispatch({
            type: types.USER_EDITABLE_DASHBOARD,
            data,
        });
};

export const resetUserEditableDashboard = () => {
    return dispatch =>
        dispatch({
            type: types.USER_EDITABLE_DASHBOARD,
            data: {},
        });
};

export const resetCustomDashboard = () => {
    return dispatch =>
        dispatch({
            type: types.RESET_CUSTOM_DASHBOARD,
        });
};

export const switchObservation = data => {
    return dispatch =>
        dispatch({
            type: types.SWITCH_OBSERVATION,
            data,
        });
};

export const changeLocation = data => {
    return dispatch =>
        dispatch({
            type: types.CHANGE_LOCATION,
            data,
        });
};

export const resetObservationList = () => {
    return dispatch =>
        dispatch({
            type: types.RESET_OBSERVATIONLIST,
        });
};

export const getSummaryParamsToApplyDailyWidget = data => {
    return dispatch =>
        dispatch({
            type: types.GET_SUMMARY_PARAMS,
            data,
        });
};

export const getEventSummaryVISUALB = actionCreator('GET_EVENTS_SUMMARY_VISUALB');
export const getEventSummaryVISUALC = actionCreator('GET_EVENTS_SUMMARY_VISUALC');
export const getEventSummaryVISUALD = actionCreator('GET_EVENTS_SUMMARY_VISUALD');
export const getDailyEvent = actionCreator('GET_DAILY_EVENT');
export const getObserList = actionCreator('GET_OBSER_LIST');
export const getEventTypeSummary = actionCreator('GET_EVENTS_TYPE_SUMMARY');
