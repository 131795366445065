import { axiosInstance } from './httpClients';
import { DEFAULT_ALL_LOCATION_ID } from '../constants';
import _ from 'lodash';
import { paramsToUrl } from '../utils';
import clientCustomizeStorage from 'storage/ClientCustomizeStorage';

export const clientConfig = () => {
    const config = JSON.parse(clientCustomizeStorage.get());
    if (!config) {
        return {
            location: undefined,
            building: undefined,
            facility: undefined,
            camera: undefined,
        };
    }
    const check = data => _.isEmpty(data) || data === '*';
    return {
        location: check(_.map(config.application.application_locations).join(','))
            ? undefined
            : _.map(config.application.application_locations).join(','),
        building: check(_.map(config.application.application_buildings).join(','))
            ? undefined
            : _.map(config.application.application_buildings).join(','),
        facility: check(_.map(config.application.application_facilities).join(','))
            ? undefined
            : _.map(config.application.application_facilities).join(','),
        camera: check(_.map(config.application.application_cameras).join(','))
            ? undefined
            : _.map(config.application.application_cameras).join(','),
    };
};

export const getObserListAPI = async data => {
    const { page_size = 10, page = 1, tag_ids, start_time, flagged, flagged_by, end_time, commented, location_ids } = data;
    const parameters = paramsToUrl({ page_size, page });
    const filteredLocationIds = location_ids.includes(DEFAULT_ALL_LOCATION_ID) ? [] : location_ids;
    const body = {
        tag_ids,
        start_time,
        flagged,
        flagged_by,
        end_time,
        commented,
        location_ids: filteredLocationIds,
    };
    return axiosInstance().post(`/api/v1/report/feed-events?${parameters}`, body);
};

export const getEventSummaryAPI = params => {
    const config = clientConfig();
    const request = { ...config, ...params };
    return axiosInstance().get('/api/v1.1/aware/events/summary', {
        params: request,
    });
};

export const getEventTypeSummaryAPI = period => {
    const config = clientConfig();
    const params = period || { period: 1 };
    const request = { ...config, ...params };
    return axiosInstance().get('/api/v1.1/aware/event-type/summary', { params: request });
};

export const getOverallAPI = params => {
    const config = clientConfig();
    const request = { ...config, ...params };
    return axiosInstance().get('/api/risk_scores/v1.0/risk_score', {
        params: request,
    });
};

export const getMetricAPI = params => {
    const config = clientConfig();
    const request = { ...config, ...params };
    return axiosInstance().get('/api/metrics/v1.0/metric', {
        params: request,
    });
};

export const getMetricSummaryAPI = params => {
    const config = clientConfig();
    const request = { ...config, ...params };
    return axiosInstance().get('/api/metrics/v1.0/summary', {
        params: request,
    });
};

export const getEventSummaryReportAPI = params => {
    const {
        page,
        page_size,
        event_group_ids,
        event_type_ids,
        cameras,
        tags,
        start_time,
        end_time,
        group_by,
        location_ids = [],
    } = params;
    const body = {
        event_group_ids,
        event_type_ids,
        cameras,
        tags,
        start_time,
        end_time,
        group_by,
        location_ids,
    };
    const parameters = paramsToUrl({ page, page_size });
    return axiosInstance().post(`/api/v1/report/summarize-event-group?${parameters}`, body);
};

export const getDailySummaryEventReportAPI = params => {
    const { start_time, end_time, event_group_ids, location_ids = [] } = params;
    const body = {
        ...(start_time && { start_time }),
        ...(end_time && { end_time }),
        ...(event_group_ids && event_group_ids[0] && { event_group_ids }),
        ...(location_ids && { location_ids }),
    };
    return axiosInstance().post(`/api/v1/report/summarize-events`, body);
};

export const getDailySummaryDataFileReportAPI = params => {
    const { start_time, end_time } = params;
    const body = {
        ...(start_time && { start_time }),
        ...(end_time && { end_time }),
    };
    return axiosInstance().post(`/api/v1/report/summarize-data-file`, body);
};

export const getUsersAPI = () => {
    return axiosInstance().get(`/api/v1/user`);
};
