class ClientCustomizeSessionStorage {
    key = 'client_customize';

    get() {
        return localStorage.getItem(this.key);
    }

    set(value) {
        localStorage.setItem(this.key, value);
    }

    del() {
        localStorage.removeItem(this.key);
    }
}

const clientCustomizeStorage = new ClientCustomizeSessionStorage();

export default clientCustomizeStorage;
