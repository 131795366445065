import { all, fork } from 'redux-saga/effects';
import awareSagas from './aware/awareSagas';
import safetySagas from './safetyCenter/safetySagas';
import mainSagas from './main/mainSagas';
import EventSagas from './eventReview/eventSagas';
import timeMarkSagas from './timeMark/timeMarkSagas';
import riskMitigationSagas from './riskMitigation/riskMitigationSagas';

export default function* rootSaga() {
    yield all([
        fork(safetySagas),
        fork(awareSagas),
        fork(mainSagas),
        fork(EventSagas),
        fork(timeMarkSagas),
        fork(riskMitigationSagas),
    ]);
}
