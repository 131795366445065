import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    getCausalRiskFactors,
    getCompleteRiskMitigation,
    getPendingRiskMitigation,
    getTotalPendingRiskMitigation,
    getRiskMitigationPriorities,
    getTotalCompleteRiskMitigation,
} from './riskMitigationAction';
import { exception } from 'constants/errorCode';
import {
    getCausalRiskFactorsAPI,
    getRiskMitigationActionItemAPI,
    getRiskMitigationPrioritiesAPI,
} from 'services/riskMitigationApis';

export default function* MainSagas() {
    yield all([
        takeLatest(getPendingRiskMitigation.REQUEST, watchGetPendingRiskMitigation),
        takeLatest(getTotalPendingRiskMitigation.REQUEST, watchGetTotalPendingRiskMitigation),
        takeLatest(getCompleteRiskMitigation.REQUEST, watchGetCompleteRiskMitigation),
        takeLatest(getTotalCompleteRiskMitigation.REQUEST, watchGetTotalCompleteRiskMitigation),
        takeLatest(getCausalRiskFactors.REQUEST, watchGetCausalRiskFactors),
        takeLatest(getRiskMitigationPriorities.REQUEST, watchGetRiskMitigationPriorities),
    ]);
}

export function* watchGetTotalPendingRiskMitigation({ data: { locationIds } }) {
    try {
        const response = yield call(getRiskMitigationActionItemAPI, { locationIds, completeInd: false });
        if (response && response.status === 200) {
            yield put(getTotalPendingRiskMitigation.success(response.data?.items));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(getTotalPendingRiskMitigation.failure(window.t(exception['500'])));
    }
}

export function* watchGetTotalCompleteRiskMitigation({ data: { locationIds } }) {
    try {
        const response = yield call(getRiskMitigationActionItemAPI, { locationIds, completeInd: true });
        if (response && response.status === 200) {
            yield put(getTotalCompleteRiskMitigation.success(response.data?.items));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(getTotalCompleteRiskMitigation.failure(window.t(exception['500'])));
    }
}

export function* watchGetPendingRiskMitigation(query) {
    try {
        const response = yield call(getRiskMitigationActionItemAPI, { ...query.data, completeInd: false });
        if (response && response.status === 200) {
            yield put(getPendingRiskMitigation.success(response.data?.items));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(getPendingRiskMitigation.failure(window.t(exception['500'])));
    }
}

export function* watchGetCompleteRiskMitigation(query) {
    try {
        const response = yield call(getRiskMitigationActionItemAPI, { ...query.data, completeInd: true });
        if (response && response.status === 200) {
            yield put(getCompleteRiskMitigation.success(response.data?.items));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(getCompleteRiskMitigation.failure(window.t(exception['500'])));
    }
}

export function* watchGetCausalRiskFactors() {
    try {
        const response = yield call(getCausalRiskFactorsAPI);
        if (response && response.status === 200) {
            yield put(getCausalRiskFactors.success(response.data?.items));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(getCausalRiskFactors.failure(window.t(exception['500'])));
    }
}

export function* watchGetRiskMitigationPriorities() {
    try {
        const response = yield call(getRiskMitigationPrioritiesAPI);
        if (response && response.status === 200) {
            yield put(getRiskMitigationPriorities.success(response.data?.items));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(getRiskMitigationPriorities.failure(window.t(exception['500'])));
    }
}
