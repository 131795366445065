import { reducerHandler } from 'redux/reduxHelper';
import {
    getCausalRiskFactors,
    getCompleteRiskMitigation,
    getPendingRiskMitigation,
    getTotalPendingRiskMitigation,
    getRiskMitigationPriorities,
    types,
    getTotalCompleteRiskMitigation,
} from './riskMitigationAction';
import { DEFAULT_ALL_LOCATION_ID } from '../../constants';

const initialAsyncState = {
    loaded: false,
    data: [],
    error: null,
};

const initialState = {
    pendingRiskMitigation: initialAsyncState,
    totalPendingRiskMitigation: initialAsyncState,
    completeRiskMitigation: initialAsyncState,
    totalCompleteRiskMitigation: initialAsyncState,
    causalRiskFactors: initialAsyncState,
    riskMitigationPriorities: initialAsyncState,
    filter: {
        targetDateFrom: null,
        targetDateTo: null,
        order: null,
    },
    currentLocation: DEFAULT_ALL_LOCATION_ID,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case getPendingRiskMitigation.REQUEST:
        case getPendingRiskMitigation.SUCCESS:
        case getPendingRiskMitigation.FAILURE:
            return {
                ...state,
                pendingRiskMitigation: reducerHandler(state.pendingRiskMitigation, action, getPendingRiskMitigation),
            };
        case getTotalPendingRiskMitigation.REQUEST:
        case getTotalPendingRiskMitigation.SUCCESS:
        case getTotalPendingRiskMitigation.FAILURE:
            return {
                ...state,
                totalPendingRiskMitigation: reducerHandler(
                    state.totalPendingRiskMitigation,
                    action,
                    getTotalPendingRiskMitigation
                ),
            };
        case getCompleteRiskMitigation.REQUEST:
        case getCompleteRiskMitigation.SUCCESS:
        case getCompleteRiskMitigation.FAILURE:
            return {
                ...state,
                completeRiskMitigation: reducerHandler(state.completeRiskMitigation, action, getCompleteRiskMitigation),
            };

        case getTotalCompleteRiskMitigation.REQUEST:
        case getTotalCompleteRiskMitigation.SUCCESS:
        case getTotalCompleteRiskMitigation.FAILURE:
            return {
                ...state,
                totalCompleteRiskMitigation: reducerHandler(
                    state.totalCompleteRiskMitigation,
                    action,
                    getTotalCompleteRiskMitigation
                ),
            };
        case getCausalRiskFactors.REQUEST:
        case getCausalRiskFactors.SUCCESS:
        case getCausalRiskFactors.FAILURE:
            return {
                ...state,
                causalRiskFactors: reducerHandler(state.causalRiskFactors, action, getCausalRiskFactors),
            };
        case getRiskMitigationPriorities.REQUEST:
        case getRiskMitigationPriorities.SUCCESS:
        case getRiskMitigationPriorities.FAILURE:
            return {
                ...state,
                riskMitigationPriorities: reducerHandler(state.riskMitigationPriorities, action, getRiskMitigationPriorities),
            };
        case types.APPLY_RISK_MITIGATION_FILTER:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.data,
                },
            };
        case types.CLEAR_RISK_MITIGATION_FILTER:
            return {
                ...state,
                filter: initialState.filter,
            };
        case types.CHANGE_LOCATION:
            return {
                ...state,
                currentLocation: action.data,
            };
        default:
            return state;
    }
}
