export const REPORT_GROUP_BY = {
    EVENT_TYPE: 'event_type',
    CAMERA: 'camera',
};

export const EVENT_GROUP_ID_MAPPING = {
    PPE: 1,
    SLIP_TRIP_FALL: 2,
    PIT_OPERATIONS: 3,
    ERGONOMICS: 4,
    SPECIAL_LOADS: 5,
    INCIDENT: 6,
    POLICIES: 7,
    COVID19: 8,
    GENERAL: 9,
    ENVIRONMENTAL: 10,
    WORKER_EXCLUSION_ZONE: 11,
    EXCLUSION_ZONE: 12,
    FALL_HAZARD: 13,
};

export const WIDGET_KEY = {
    GROUP_TYPES: 'group.types',
    DATE_RANGE: 'date.range',
    DATE_RANGE_LIMITED_MENU: 'date.range.limited.menu',
    SUMMARY_O: 'summary.card',
    GROUP_TYPE: 'group.type',
    EVENT_TYPES: 'event.types',
    GROUP_BY: 'group.by',
    DATA_TYPE: 'data.type',
    TAGS: 'tags',
    FLAGGED: 'flagged',
    FLAGGED_BY: 'flagged.by',
    COMMENTED: 'commented',
    EVENT_GROUP_IDS: 'event.group.ids',
    LOCATION_IDS: 'location.ids',
    EVENT_GROUP_ID: 'event_group_id',
    EVENT_TYPE_IDS: 'event_type_ids',
};

export const DAILY_SUMMARY_DATA_TYPE = {
    EVENT_DATA: 'event_data',
    VIDEO_DATA: 'video_data',
};
