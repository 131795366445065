class DashboardStorage {
    key = 'dashboard_data';

    get() {
        return localStorage.getItem(this.key);
    }

    set(value) {
        localStorage.setItem(this.key, value);
    }

    del() {
        localStorage.removeItem(this.key);
    }
}

const dashboardStorage = new DashboardStorage();

export default dashboardStorage;
