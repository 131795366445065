import { LANGUAGE } from 'constants/index';
import _ from 'lodash';
import userStorage from 'storage/UserStorage';

function createGuid() {
    function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    return (S4() + S4() + '-' + S4() + '-4' + S4().substr(0, 3) + '-' + S4() + '-' + S4() + S4() + S4()).toLowerCase();
}
// eslint-disable-next-line no-extend-native
String.prototype.toGuid = function() {
    return createGuid();
};
/**
 * @param {number} number The number
 */
export function isNaNandInfinite(number) {
    if (_.isNumber(number)) return !isNaN(number) && isFinite(number);
    return false;
}

export function getLang() {
    try {
        return JSON.parse(userStorage.get())?.language || LANGUAGE[0]?.id;
    } catch (e) {
        return LANGUAGE[0]?.id;
    }
}

export const disableChromeShortcutKey = event => {
    // console.log({ event })
    if (event.altKey && event.keyCode === 37) {
        event.preventDefault();
        return false;
    }
    if (event.metaKey && event.keyCode === 219) {
        event.preventDefault();
        return false;
    }
};

export const validName = new RegExp('^[A-Za-z][A-Za-z0-9-_./]*');
