class UserStorage {
    key = 'current_user';

    get() {
        return localStorage.getItem(this.key);
    }

    set(value) {
        localStorage.setItem(this.key, value);
    }

    del() {
        localStorage.removeItem(this.key);
    }
}
const userStorage = new UserStorage();
export default userStorage;
