// COPYRIGHT (C) [2023], CompScience, Inc.
// This software is proprietary and confidential. Unauthorized copying,
// distribution, modification, or use is strictly prohibited. This software is provided
// "as is," without warranty of any kind.

import { actionCreator } from '../reduxHelper';
import { updateUserConfig } from 'services/mainApi';
import { message } from '../../common/message';

export const types = {
    GET_DASHBOARD_CONFIG: 'GET_DASHBOARD_CONFIG',
    CHANGE_USER_CONFIG: 'CHANGE_USER_CONFIG',
    TOGGLE_TAG_MANAGER: 'TOGGLE_TAG_MANAGER',
    TOGGLE_TIME_MARKS: 'TOGGLE_TIME_MARKS',
    CHANGE_ACTION_STATUS: 'CHANGE_ACTION_STATUS',
};

export const changeUserConfig = data => ({
    type: types.CHANGE_USER_CONFIG,
    data,
});

export const toggleTagManager = data => {
    return dispatch =>
        dispatch({
            type: types.TOGGLE_TAG_MANAGER,
            data,
        });
};

export const toggleTimeMarks = data => {
    return dispatch =>
        dispatch({
            type: types.TOGGLE_TIME_MARKS,
            data,
        });
};

export function saveUserConfigToDatabase(userConfig = {}, data = {}, callback = null) {
    const saveData = { ...userConfig, ...data };
    return function(dispatch) {
        return updateUserConfig(saveData).then(res => {
            if (Boolean(res.data.status)) {
                dispatch({
                    type: types.CHANGE_USER_CONFIG,
                    data: saveData,
                });
                if (callback) {
                    callback();
                }
            } else {
                message.error(window.t('Something went wrong'), window.t('Please try again later!'));
            }
        });
    };
}

export const changeTrackingActionStatus = status => ({
    type: types.CHANGE_ACTION_STATUS,
    data: status,
});

export const getEventType = actionCreator('GET_EVENT_TYPE');
export const getLocation = actionCreator('GET_LOCATION');
export const getCamera = actionCreator('GET_CAMERA');
export const getMetricType = actionCreator('GET_METRIC_TYPE');
export const getBuilding = actionCreator('GET_BUILDING');
export const getMetricCount = actionCreator('GET_METRIC_COUNT');
export const getTagList = actionCreator('TAG_LIST');
export const getUserList = actionCreator('USER_LIST');
