import { axiosInstance } from './httpClients';
import { STATUSES } from '../constants/index';
import { paramsToUrl } from '../utils';

const request = axiosInstance();

export const getCommentAPI = eventId => {
    return request.get(`/api/v1.1/comments?event_id=${eventId}`);
};

export const getVideoTrackingAPI = eventId => {
    return request.get(`/api/v1.1/events/${eventId}/event_tracking`, {
        requestId: 'event_tracking_requests',
    });
};

export const getTrackingAPI = eventId => {
    return request.get(`/api/v1.1/events/${eventId}/tracking`, {
        requestId: 'event_tracking_requests',
    });
};

export const rejectEventAPI = ({ eventId, reason }) => {
    return request.post(`/api/v1.1/events/${eventId}/reject`, {
        reason,
    });
};

export const getTagsListAPI = (params = {}) => {
    params.state = [STATUSES.new.id, STATUSES.confirmed.id];
    const parameters = paramsToUrl(params);
    return request.get(`/api/v1.1/tags?${parameters}`);
};

export const getEventTagAPI = ({ eventId, params }) => {
    return request.get(`/api/v1.1/events/${eventId}/tag`, {
        params,
    });
};

export const postEventTags = ({ eventId, tagId }) => {
    return request.post(`/api/v1.1/events/${eventId}/tag`, {
        tag_id: tagId,
    });
};

export const deleteEventTagsAPI = ({ eventId, tagId }) => {
    return request.delete(`/api/v1.1/events/${eventId}/tag/${tagId}`);
};

export const getTagCategoryAPI = params => {
    return request.get(`/api/v1.1/tag-category`, { params });
};

export const postTagCategoryAPI = name => {
    return request.post(`/api/v1.1/tag-category`, { name });
};

export const updateTagDetailAPI = ({ tag_id, name, display_name, tag_category_id, enabled }) => {
    return request.put(`/api/v1.1/tags/${tag_id}`, { name, display_name, tag_category_id, enabled });
};

export const deleteTagCategoryById = id => {
    return request.delete(`/api/v1.1/tag-category/${id}`);
};

export const deleteTagNameById = id => {
    return request.delete(`/api/v1.1/tags/${id}`);
};

export const createTagDetailAPI = ({ name, display_name, tag_category_id, enabled }) => {
    return request.post(`/api/v1.1/tags`, {
        name,
        display_name,
        tag_category_id,
        enabled,
    });
};

export const getAllSeverities = () => {
    return request.get('/api/v1.1/severity');
};

export const updateNote = ({ value, eventId }) => {
    return request.put(`/api/v1.1/events/${eventId}/notes`, {
        value,
    });
};

export const updateEventType = ({ value, eventId }) => {
    return request.put(`/api/v1.1/events/${eventId}/event-type`, {
        value,
    });
};

export const updateEventClassification = ({ value, eventId }) => {
    return request.put(`/api/v1.1/events/${eventId}/event-classification`, {
        value,
    });
};

export const updateSeverity = ({ value, eventId }) => {
    return request.put(`/api/v1.1/events/${eventId}/severity`, {
        value,
    });
};

export const exportEvent = payload => {
    return request.post(`/api/v1.1/event-sharing-clips`, payload);
};

export const getExportEventStatus = id => {
    return request.get(`/api/v1.1/event-sharing-clips/${id}`);
};

export const getExportEventDownloadUrl = id => {
    return request.get(`/api/v1.1/event-sharing-clips/${id}/download`);
};
