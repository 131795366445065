import {
    createStore,
    applyMiddleware,
    compose
} from 'redux'
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import rootSagas from 'redux/rootSaga.js';
import rootReducers from "redux/rootReducer.js";

const saga = createSagaMiddleware();

const middleWares = [saga, thunk]

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

export const store = createStore(rootReducers, composeEnhancers(applyMiddleware(...middleWares)));

saga.run(rootSagas)
