class RefreshTokenStorage {
    key = 'refresh_token';

    get() {
        return localStorage.getItem(this.key);
    }

    set(value) {
        localStorage.setItem(this.key, value);
    }

    del() {
        localStorage.removeItem(this.key);
    }
}

const refreshTokenStorage = new RefreshTokenStorage();

export default refreshTokenStorage;
