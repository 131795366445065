// COPYRIGHT (C) [2023], CompScience, Inc.
// This software is proprietary and confidential. Unauthorized copying,
// distribution, modification, or use is strictly prohibited. This software is provided
// "as is," without warranty of any kind.

import {
    getBuilding,
    getCamera,
    getEventType,
    getLocation,
    getMetricType,
    getMetricCount,
    getTagList,
    types,
    getUserList,
} from './mainAction.js';
import { reducerHandler } from '../reduxHelper';
import { DATA_DISTANCE, LANGUAGE } from 'constants/index.js';
import { isArray } from 'lodash';
import userStorage from 'storage/UserStorage.js';

const initialAsyncState = {
    isLoading: false,
    loaded: false,
    data: null,
    error: null,
};

let currentUser;
try {
    currentUser = JSON.parse(userStorage.get());
} catch (e) {
    currentUser = {};
}

const getUserDistance = () => {
    if (currentUser?.distance) return currentUser?.distance;

    return isArray(DATA_DISTANCE[currentUser?.language ?? LANGUAGE[0].id])
        ? DATA_DISTANCE[currentUser?.language ?? LANGUAGE[0].id][0].id
        : DATA_DISTANCE[LANGUAGE[0].id][0].id;
};

const initialState = {
    event_type: initialAsyncState,
    location: initialAsyncState,
    cameras: initialAsyncState,
    dashboards: initialAsyncState,
    metric_type: initialAsyncState,
    metric_count_api: initialAsyncState,
    building: initialAsyncState,
    userProfile: {
        language: currentUser?.language ?? LANGUAGE[0].id,
        distance: getUserDistance(),
        time_format: currentUser?.time_format ?? 'hh:mm A',
        date_format: currentUser?.date_format ?? 'MM-DD-YYYY',
        playback_speed: 1,
    },
    tagManagerModal: false,
    tagList: initialAsyncState,
    timeMarksModal: false,
    userList: initialAsyncState,
    actionStatus: 'DOWNLOAD',
};

export default function(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_USER_CONFIG:
            return {
                ...state,
                userProfile: { ...state.userProfile, ...action.data },
            };
        case getEventType.REQUEST:
        case getEventType.SUCCESS:
        case getEventType.FAILURE:
            return {
                ...state,
                event_type: reducerHandler(state.event_type, action, getEventType),
            };
        case getLocation.REQUEST:
        case getLocation.SUCCESS:
        case getLocation.FAILURE:
            return {
                ...state,
                location: reducerHandler(state.location, action, getLocation),
            };
        case getCamera.REQUEST:
        case getCamera.SUCCESS:
        case getCamera.FAILURE:
            return {
                ...state,
                cameras: reducerHandler(state.cameras, action, getCamera),
            };
        case getMetricType.REQUEST:
        case getMetricType.SUCCESS:
        case getMetricType.FAILURE:
            return {
                ...state,
                metric_type: reducerHandler(state.metric_type, action, getMetricType),
            };
        case getMetricCount.REQUEST:
        case getMetricCount.SUCCESS:
        case getMetricCount.FAILURE:
            return {
                ...state,
                metric_count_api: reducerHandler(state.metric_count_api, action, getMetricCount),
            };
        case getBuilding.REQUEST:
        case getBuilding.SUCCESS:
        case getBuilding.FAILURE:
            return {
                ...state,
                building: reducerHandler(state.building, action, getBuilding),
            };
        case types.TOGGLE_TAG_MANAGER: {
            return {
                ...state,
                tagManagerModal: action.data,
            };
        }
        case types.TOGGLE_TIME_MARKS: {
            return {
                ...state,
                timeMarksModal: action.data,
            };
        }
        case getTagList.REQUEST:
        case getTagList.SUCCESS:
        case getTagList.FAILURE:
            return {
                ...state,
                tagList: reducerHandler(state.tagList, action, getTagList),
            };
        case getUserList.REQUEST:
        case getUserList.SUCCESS:
        case getUserList.FAILURE:
            return {
                ...state,
                userList: reducerHandler(state.userList, action, getUserList),
            };
        case types.CHANGE_ACTION_STATUS: {
            return {
                ...state,
                actionStatus: action.data,
            };
        }
        default:
            return state;
    }
}
