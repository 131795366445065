import { actionCreator } from 'redux/reduxHelper';

export const types = {
    GET_PENDING_RISK_MITIGATION: 'GET_PENDING_RISK_MITIGATION',
    GET_TOTAL_PENDING_RISK_MITIGATION: 'GET_TOTAL_PENDING_RISK_MITIGATION',
    GET_COMPLETE_RISK_MITIGATION: 'GET_COMPLETE_RISK_MITIGATION',
    GET_TOTAL_COMPLETE_RISK_MITIGATION: 'GET_TOTAL_COMPLETE_RISK_MITIGATION',
    GET_CAUSAL_RISK_FACTORS: 'GET_CAUSAL_RISK_FACTORS',
    GET_RISK_MITIGATION_PRIORITIES: 'GET_RISK_MITIGATION_PRIORITIES',
    APPLY_RISK_MITIGATION_FILTER: 'APPLY_RISK_MITIGATION_FILTER',
    CLEAR_RISK_MITIGATION_FILTER: 'CLEAR_RISK_MITIGATION_FILTER',
    CHANGE_LOCATION: 'CHANGE_LOCATION',
};

export const getPendingRiskMitigation = actionCreator(types.GET_PENDING_RISK_MITIGATION);
export const getTotalPendingRiskMitigation = actionCreator(types.GET_TOTAL_PENDING_RISK_MITIGATION);
export const getCompleteRiskMitigation = actionCreator(types.GET_COMPLETE_RISK_MITIGATION);
export const getTotalCompleteRiskMitigation = actionCreator(types.GET_TOTAL_COMPLETE_RISK_MITIGATION);
export const getCausalRiskFactors = actionCreator(types.GET_CAUSAL_RISK_FACTORS);
export const getRiskMitigationPriorities = actionCreator(types.GET_RISK_MITIGATION_PRIORITIES);

export const applyRiskMitigationFilter = data => {
    return dispatch =>
        dispatch({
            type: types.APPLY_RISK_MITIGATION_FILTER,
            data,
        });
};

export const clearRiskMitigationFilter = () => {
    return dispatch =>
        dispatch({
            type: types.CLEAR_RISK_MITIGATION_FILTER,
        });
};

export const changeLocation = locationId => {
    return dispatch =>
        dispatch({
            type: types.CHANGE_LOCATION,
            data: locationId,
        });
};
