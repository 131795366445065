import axios from 'axios';
import axiosCancel from 'axios-cancel';
import serverSettings from './../serverSettings.json';
import { createBrowserHistory } from 'history';
import { getLang } from 'common/extensions';
import { reLoginDebounce, accountRefreshToken } from '../utils/keycloak';
import jwt_decode from 'jwt-decode';
import _ from 'lodash';
import tokenStorage from 'storage/TokenStorage';

export const history = createBrowserHistory();
const ACCEPT_TOKEN_VERSION = 2.15;

/** Return true if token/refresh token is expired or going to expire within inNextSeconds */
export function isTokenExpiredSoon(decodeToken, inNextSeconds) {
    if (!decodeToken) {
        return true;
    }

    const expTimestamp = decodeToken.exp ? decodeToken.exp * 1000 : undefined;
    if (expTimestamp && expTimestamp - Date.now() < inNextSeconds * 1000) {
        return true;
    }

    return false;
}

export function safe_jwt_decode(token) {
    try {
        // Ignore if the local storaged an invalid format token
        return jwt_decode(token, { complete: true });
    } catch (err) {
        return null;
    }
}

export const axiosInstance = () => {
    if (!document.defaultAxios) {
        document.defaultAxios = axios.create({
            baseURL: serverSettings.baseUrl[process.env.DEVELOPMENT],
            headers: {
                'Content-type': 'application/json',
                Language: getLang(),
            },
            timeout: 30000,
        });
        axiosCancel(document.defaultAxios, {
            debug: true, // default
        });
        document.defaultAxios.interceptors.request.use(
            async config => {
                let token = tokenStorage.get();
                if (token) {
                    const decodeToken = safe_jwt_decode(token);
                    const currentVersion = _.get(decodeToken, 'version');
                    if (
                        !currentVersion ||
                        _.toNumber(currentVersion) < ACCEPT_TOKEN_VERSION ||
                        isTokenExpiredSoon(decodeToken, 3)
                    ) {
                        token = await accountRefreshToken();
                    }
                }

                if (token) {
                    config.headers['Authorization'] = 'Bearer ' + token;
                }

                return config;
            },
            error => {
                if (error?.response?.status === 401) {
                    reLoginDebounce();
                } else {
                    return Promise.reject(error);
                }
            }
        );

        document.defaultAxios.interceptors.response.use(
            res => {
                return res;
            },
            async err => {
                const originalConfig = err.config;

                if (err.response?.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;

                    try {
                        await accountRefreshToken();
                        return document.defaultAxios(originalConfig);
                    } catch (_error) {
                        reLoginDebounce();
                    }
                } else if (err.response?.status === 401 && originalConfig._retry) {
                    reLoginDebounce();
                } else {
                    return Promise.reject(err);
                }
            }
        );
    }
    return document.defaultAxios;
};
