import { combineReducers } from 'redux';
import authReducer from 'redux/auth/authReducer';
import parentEventTypeReducer from 'reducers/parentEventTypeReducer.js';
import aware from 'redux/aware/awareReducer.js';
import main from 'redux/main/mainReducer.js';
import riskAnalytic from 'redux/riskAnalytic/riskReducer.js';
import eventReducer from './eventReview/eventReducer';
import timeMarkReducer from './timeMark/timeMarkReducer';
import riskMitigationReducer from './riskMitigation/riskMitigationReducer';

export default combineReducers({
    events: eventReducer,
    auth: authReducer,
    parentEventType: parentEventTypeReducer,
    riskAnalytic,
    aware,
    main,
    timeMark: timeMarkReducer,
    riskMitigation: riskMitigationReducer,
});
