import { reducerHandler } from '../reduxHelper';
import _ from 'lodash';
import {
    getTimeMarkCategories,
    getTimeMarksByCategory,
    createTimeMarkAction,
    deleteTimeMarkAction,
    updateTimeMarkAction,
    getTimeMarkIcon,
    getAllTimeMarks,
} from './timeMarkAction.js';
import { message } from '../../common/message';
import userStorage from 'storage/UserStorage';

const initialAsyncState = {
    isLoading: false,
    loaded: false,
    data: null,
    error: null,
};

const initialState = {
    timeMarkCategories: initialAsyncState,
    timeMarksByCategory: initialAsyncState,
    createTimeMark: initialAsyncState,
    deleteTimeMark: initialAsyncState,
    updateTimeMark: initialAsyncState,
    timeMarkIcon: initialAsyncState,
    allTimeMarks: initialAsyncState,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case getTimeMarkCategories.REQUEST:
        case getTimeMarkCategories.SUCCESS:
        case getTimeMarkCategories.FAILURE:
            return {
                ...state,
                timeMarkCategories: reducerHandler(state.timeMarkCategories, action, getTimeMarkCategories),
            };
        case getTimeMarksByCategory.REQUEST:
        case getTimeMarksByCategory.SUCCESS:
        case getTimeMarksByCategory.FAILURE:
            return {
                ...state,
                timeMarksByCategory: reducerHandler(state.timeMarksByCategory, action, getTimeMarksByCategory),
            };
        case getAllTimeMarks.REQUEST:
        case getAllTimeMarks.SUCCESS:
        case getAllTimeMarks.FAILURE:
            return {
                ...state,
                allTimeMarks: reducerHandler(state.allTimeMarks, action, getAllTimeMarks),
            };
        case createTimeMarkAction.REQUEST:
            return {
                ...state,
                createTimeMark: reducerHandler(state.createTimeMark, action, createTimeMarkAction),
            };
        case createTimeMarkAction.SUCCESS: {
            let currentUser = userStorage.get();
            if (currentUser) {
                currentUser = JSON.parse(currentUser);
            } else {
                currentUser = { first_name: 'Anonymous' };
            }
            const data = { ...action?.data, owner_name: currentUser.first_name };
            let allTimeMarks = { ...state.allTimeMarks, data: [data, ...state.allTimeMarks.data] };
            message.success(window.t('Action Tracking'), window.t('Create new action tracking successfully'));
            return {
                ...state,
                createTimeMark: reducerHandler(state.createTimeMark, action, createTimeMarkAction),
                allTimeMarks,
            };
        }
        case createTimeMarkAction.FAILURE: {
            message.error(window.t('Something went wrong'), window.t('Please try again later!'));
            return {
                ...state,
                createTimeMark: reducerHandler(state.createTimeMark, action, createTimeMarkAction),
            };
        }
        case deleteTimeMarkAction.REQUEST:
            return {
                ...state,
                deleteTimeMark: reducerHandler(state.deleteTimeMark, action, deleteTimeMarkAction),
            };
        case deleteTimeMarkAction.SUCCESS: {
            const allTimeMarks = {
                ...state.allTimeMarks,
                data: (_.get(state, 'allTimeMarks.data') || []).filter(item => item.id !== action.data),
            };
            message.success(window.t('Action Tracking'), window.t('Delete action tracking successfully'));
            return {
                ...state,
                deleteTimeMark: reducerHandler(state.deleteTimeMark, action, deleteTimeMarkAction),
                allTimeMarks,
            };
        }
        case deleteTimeMarkAction.FAILURE: {
            message.error(window.t('Something went wrong'), window.t('Please try again later!'));
            return {
                ...state,
                deleteTimeMark: reducerHandler(state.deleteTimeMark, action, deleteTimeMarkAction),
            };
        }
        case updateTimeMarkAction.REQUEST:
            return {
                ...state,
                updateTimeMark: reducerHandler(state.updateTimeMark, action, updateTimeMarkAction),
            };
        case updateTimeMarkAction.SUCCESS: {
            const data = action?.data;
            const timeMarksData = (state.allTimeMarks.data || []).map(item => {
                if (item.id === data?.id) return data;
                return item;
            });
            const allTimeMarks = {
                ...state.allTimeMarks,
                data: timeMarksData,
            };
            message.success(window.t('Action Tracking'), window.t('Update action tracking successfully'));
            return {
                ...state,
                updateTimeMark: reducerHandler(state.updateTimeMark, action, updateTimeMarkAction),
                allTimeMarks,
            };
        }
        case updateTimeMarkAction.FAILURE: {
            message.error(window.t('Something went wrong'), window.t('Please try again later!'));
            return {
                ...state,
                updateTimeMark: reducerHandler(state.updateTimeMark, action, updateTimeMarkAction),
            };
        }
        case getTimeMarkIcon.REQUEST:
        case getTimeMarkIcon.SUCCESS:
        case getTimeMarkIcon.FAILURE:
            return {
                ...state,
                timeMarkIcon: reducerHandler(state.timeMarkIcon, action, getTimeMarkIcon),
            };
        default:
            return state;
    }
}
