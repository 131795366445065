import React from 'react';
import ReactDOM from 'react-dom';
import { install } from 'resize-observer';
import App from 'layouts/App';

import 'assets/css/material-dashboard-react.css?v=1.8.0';

install();

ReactDOM.render(<App />, document.getElementById('root'));

window.scrollTo(0, 1);
if (window.navigator && navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
        for (let registration of registrations) {
            registration.unregister();
        }
    });
}
