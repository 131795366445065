import _ from 'lodash';
import { types } from './riskAction.js';

const initialState = {
    custom_risk_analytic: {},
    fetch_risk_analytic: {},
    chart_selected: {},
    isReadyCharts: false,
};

export default function RiskReducer(state = initialState, action) {
    switch (action.type) {
        case types.CUSTOM_RISK_ANALYTIC:
            return {
                ...state,
                custom_risk_analytic: Object.assign(state.custom_risk_analytic, action.data),
            };
        case types.FETCH_RISK_ANALYTIC:
            return {
                ...state,
                fetch_risk_analytic: Object.assign(_.cloneDeep(state.fetch_risk_analytic), action.data),
            };
        case types.SWITCH_CHART_RISK_ANALYTIC:
            return {
                ...state,
                chart_selected: Object.assign(_.cloneDeep(state.chart_selected), action.data),
            };
        case types.RESET_RISK_ANALYTIC:
            return { ...initialState };
        case types.SET_IS_READY_CHART:
            return { ...state, isReadyCharts: action.data };
        default:
            return state;
    }
}
