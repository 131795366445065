import { CircularProgress } from '@material-ui/core';
import { fetchAccessibleClients, getCurrentUserInfo } from 'actions/clientLoginAction';
import { message } from 'common/message';
import ErrorBoundary from 'components/ErrorBoundary';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useAsync } from 'react-use';
import { bindActionCreators } from 'redux';
import { verifyPermiss } from 'redux/auth/authAction';
import { getTagCategory } from 'redux/eventReview/eventAction';
import { getCamera, getEventType, getMetricCount, getMetricType, saveUserConfigToDatabase } from 'redux/main/mainAction';
import { getAllTimeMarks } from 'redux/timeMark/timeMarkAction';
import { getPermission } from 'services/mainApi';
import clientCustomizeStorage from 'storage/ClientCustomizeStorage';
import currentClientStorage from 'storage/CurrentClientStorage';
import userStorage from 'storage/UserStorage';
import Swal from 'sweetalert2';
import { hashClientId } from 'utils/hashcode';
import { updateClientOnLocalStorage } from '../actions/clientLoginAction';
import { getLang } from '../common/extensions';
import { getClientDashboardConfigs, getCurrentUser, getSelectedDashboardConfigJSON } from '../utils';
import { accountRefreshToken } from '../utils/keycloak';

const Main = React.lazy(() => import('./Main'));

const ERROR_TYPE = {
    SESSION_EXPIRE: 'SESSION_EXPIRE',
    UNKNOWN_ERROR: 'UNKNOWN_ERROR',
};

function Parent(props) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [currentClient, setCurrentClient] = useState(JSON.parse(currentClientStorage.get()));

    const redirectToClientLogin = () => {
        const redirect_uri = encodeURIComponent(window.location.href);
        window.location.href = '/client-login?redirect_uri=' + redirect_uri;
    };

    const refreshUserAndClientInfo = async () => {
        try {
            const { first_name, last_name, client_name, user_type, account_type, selected_client } = await getCurrentUserInfo();
            const currentUser = getCurrentUser();
            userStorage.set(
                JSON.stringify({
                    ...currentUser,
                    first_name,
                    last_name,
                    user_type,
                    account_type,
                    client_name,
                })
            );

            if (selected_client) {
                if (!selected_client.client_segment) {
                    selected_client.client_segment = '';
                }
                currentClientStorage.set(JSON.stringify(selected_client));
            } else {
                redirectToClientLogin();
            }
        } catch (error) {
            throw new Error(ERROR_TYPE.SESSION_EXPIRE);
        }
    };

    const findAccessibleClients = async encodedClientId => {
        let page = 1;
        let selectedClient = {};
        const pageSize = 30;
        while (true) {
            const res = await fetchAccessibleClients(page, '', pageSize);
            if (!res?.items) {
                break;
            }
            const client = res.items.find(item => hashClientId(item.client_id) === encodedClientId);
            if (client) {
                selectedClient = {
                    client_id: client.client_id,
                    client_name: client.client_name,
                    client_segment: client.client_segment || '',
                };
            }

            page += 1;
            if (!res.has_next) break;
        }
        return selectedClient;
    };

    const reloadClienByEncodedClientId = async encodedClientId => {
        const client = await findAccessibleClients(encodedClientId);
        if (!isEmpty(client)) {
            await updateClientOnLocalStorage(client);
            window.location.reload();
        }
        return;
    };

    const detectCurrentClient = async () => {
        try {
            const url = new URL(window.location.href);
            const client_hash = url.searchParams.get('client_hash');

            if (client_hash) {
                //remove client customize when get new client from client_hash to avoid get overview dashboard of old client
                clientCustomizeStorage.del();
                if (currentClientStorage.get()) {
                    const currentClient = JSON.parse(currentClientStorage.get());
                    if (hashClientId(currentClient?.id) === client_hash) return;
                }
                await reloadClienByEncodedClientId(client_hash);
                return;
            }

            if (currentClientStorage.get()) {
                await refreshUserAndClientInfo();
                return;
            }

            const res = await fetchAccessibleClients(1, '');
            if (res.items?.length === 1) {
                const currentClient = res.items[0];
                currentClientStorage.set(
                    JSON.stringify({
                        id: currentClient.client_id,
                        name: currentClient.client_name,
                        client_segment: currentClient.client_segment || '',
                    })
                );
            } else {
                const redirect_uri = encodeURIComponent(window.location.href);
                window.location.href = '/client-login?redirect_uri=' + redirect_uri;
            }
        } catch (error) {
            if (error.message === ERROR_TYPE.SESSION_EXPIRE) {
                throw new Error(ERROR_TYPE.SESSION_EXPIRE);
            }
            throw new Error(ERROR_TYPE.UNKNOWN_ERROR);
        }
    };

    const { error } = useAsync(async () => {
        if (!loading) {
            return;
        }
        const currentUser = getCurrentUser();
        if (!currentUser?.id) {
            return;
        }
        await detectCurrentClient();
        const dashboardData = await getClientDashboardConfigs();
        const permission = await getPermission();
        const clientCustomize = getSelectedDashboardConfigJSON();
        if (
            (!clientCustomize || clientCustomize.config || !dashboardData.find(item => item.id === clientCustomize.id)) &&
            currentUser.id
        ) {
            clientCustomizeStorage.set(JSON.stringify(dashboardData[0]));
        }

        if (Boolean(permission.data?.status)) {
            let value = permission.data.permissions ?? [];
            if (!some(value)) value = [];
            const currentUser = JSON.parse(userStorage.get());
            const copyCurrentUser = { ...currentUser, permissions: value, language: getLang() };
            dispatch(verifyPermiss(value));
            userStorage.set(JSON.stringify(copyCurrentUser));
            setLoading(false);
            return false;
        } else {
            setLoading(false);
            throw new Error(ERROR_TYPE.UNKNOWN_ERROR);
        }
    }, [props?.main?.userProfile]);

    useEffect(() => {
        if (error) {
            if (error.message === ERROR_TYPE.SESSION_EXPIRE) {
                handleTokenExpired();
            } else {
                message.error(window.t('Something went wrong'), window.t('Please try again later!'));
            }
        }
    }, [error]);

    const handleTokenExpired = async () => {
        const newToken = await accountRefreshToken();
        if (newToken) {
            window.location.reload();
        }
    };

    const callAPi = useCallback(async () => {
        dispatch(getEventType.request());
        dispatch(getCamera.request());
        dispatch(getMetricType.request());
        dispatch(getMetricCount.request());
        dispatch(getAllTimeMarks.request());
        dispatch(getTagCategory.request());
    }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        callAPi();
    }, [callAPi]);

    useEffect(() => {
        function storageChange(event) {
            if (event.key === 'current_client') {
                const client = JSON.parse(currentClientStorage.get());
                if (!currentClient) {
                    setCurrentClient(client);
                    return;
                }

                if (currentClient.id !== client?.id) {
                    Swal.fire({
                        title: window.t('Client context changed'),
                        text: window.t('You have opened another tab with a different client data, please click below to reload.'),
                        showCancelButton: false,
                        confirmButtonText: window.t('Reload'),
                        allowOutsideClick: false,
                        customClass: {
                            text: 'delete_modal__text',
                            title: 'delete_modal__title',
                            content: 'delete_modal__content',
                            loader: 'delete_modal__loader',
                        },
                        preConfirm: async () => {
                            const { id, name, client_segment = '' } = currentClient;
                            await updateClientOnLocalStorage({ client_id: id, client_name: name, client_segment });
                            window.location.reload();
                        },
                    });
                }
            }
        }
        window.addEventListener('storage', storageChange, false);

        return () => {
            window.removeEventListener('storage', storageChange, false);
        };
    }, []);

    if (loading)
        return (
            <div className="display-center full-screen">
                <CircularProgress />
            </div>
        );

    return (
        <ErrorBoundary>
            <Suspense
                fallback={
                    <div className="display-center full-screen">
                        <CircularProgress />
                    </div>
                }
            >
                <Main>{props.children}</Main>
            </Suspense>
        </ErrorBoundary>
    );
}

const mapState = state => ({
    shape: state.parentEventType.shape,
    main: state.main,
});

const mapDispatch = dispatch => ({
    saveUserConfigToDatabase: bindActionCreators(saveUserConfigToDatabase, dispatch),
});

export default connect(
    mapState,
    mapDispatch
)(Parent);
