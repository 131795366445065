import { call, put, takeLatest, all } from 'redux-saga/effects';
import {
    getBuilding,
    getCamera,
    getEventType,
    getLocation,
    getMetricType,
    getMetricCount,
    getTagList,
    types,
    getUserList,
} from './mainAction.js';
import {
    getCameraAPI,
    getLocationAPI,
    getEventTypeAPI,
    getMetricTypeAPI,
    getBuildingAPI,
    getMetricTypeAPI1,
} from 'services/mainApi';
import { DEFAULT_CAMERA, DEFAULT_ALL_LOCATION, DEFAULT_BUILDING } from 'constants/index';
import i18n from 'locales/index.js';
import moment from 'common/momentExtensions';
import langCodes from 'constants/langCodes.js';
import React from 'react';
import _ from 'lodash';
import { getTagsListAPI } from 'services/eventApis.js';
import { message } from 'common/message.js';
import { exception } from 'constants/errorCode';
import { getUsersAPI } from 'services/awareApis';
import userStorage from 'storage/UserStorage.js';

export default function* MainSagas() {
    yield all([
        takeLatest(getEventType.REQUEST, watchGetEventType),
        takeLatest(getLocation.REQUEST, watchGetLocation),
        takeLatest(getCamera.REQUEST, watchGetCamera),
        takeLatest(getMetricType.REQUEST, watchGetMetricType),
        takeLatest(getMetricCount.REQUEST, watchGetMetricType1),
        takeLatest(getBuilding.REQUEST, watchGetBuilding),
        takeLatest(types.CHANGE_USER_CONFIG, watchChangeUserConfig),
        takeLatest(getTagList.REQUEST, watchGetTagList),
        takeLatest(getUserList.REQUEST, watchGetUserList),
    ]);
}

export function* watchGetEventType(data) {
    try {
        //testing sagas with random api
        const response = yield call(getEventTypeAPI);
        if (response && response.status === 200) {
            yield put(getEventType.success(response.data));
        } else {
            getEventType.failure(response.data);
        }
    } catch (error) {
        yield put(getEventType.failure(error));
    }
}

export function* watchGetLocation(params) {
    try {
        const response = yield call(getLocationAPI, params);
        if (response && response.status === 200) {
            response.data.data.sort((a, b) => a.name.localeCompare(b.name));
            let all = { ...DEFAULT_ALL_LOCATION };
            response.data.data.unshift(all);
            yield put(getLocation.success(response.data));
        }
    } catch (error) {
        yield put(getLocation.failure(window.t(exception['500'])));
    }
}

export function* watchGetMetricType(params) {
    try {
        const response = yield call(getMetricTypeAPI, params);
        if (response && response.status === 200) {
            // const data = _.map(response.data.data, (val) => ({ id: val, name: val }));
            yield put(getMetricType.success(response.data.data));
        }
    } catch (error) {
        yield put(getMetricType.failure(window.t(exception['500'])));
    }
}

export function* watchGetMetricType1(params) {
    try {
        const response = yield call(getMetricTypeAPI1, params);
        if (response && response.status === 200) {
            // const data = _.map(response.data.data, (val) => ({ id: val, name: val }));
            yield put(getMetricCount.success(response.data.data));
        }
    } catch (error) {
        yield put(getMetricCount.failure(window.t(exception['500'])));
    }
}

export function* watchGetCamera() {
    try {
        const response = yield call(getCameraAPI);
        if (response && response.status === 200) {
            let all = { ...DEFAULT_CAMERA };
            response.data.data.unshift(all);
            yield put(getCamera.success(response.data));
        }
    } catch (error) {
        yield put(getCamera.failure(window.t(exception['500'])));
    }
}

export function* watchGetBuilding() {
    try {
        const response = yield call(getBuildingAPI);
        if (response && response.status === 200) {
            let all = { ...DEFAULT_BUILDING };
            response.data.data.unshift(all);
            yield put(getBuilding.success(response.data));
        }
    } catch (error) {
        yield put(getBuilding.failure(window.t(exception['500'])));
    }
}

export function* watchGetTagList({ data }) {
    try {
        const response = yield call(getTagsListAPI, data);

        if (response && response.status === 200) {
            response.data.data.sort((tagX, tagY) => tagX.name.localeCompare(tagY.name, undefined, { sensitivity: 'accent' }));
            yield put(getTagList.success(response.data.data));
        }
    } catch (error) {
        message.error(window.t('Something went wrong'));
        yield put(getTagList.failure(window.t(exception['500'])));
    }
}

export function* watchChangeUserConfig(data) {
    const getCurrentUser = JSON.parse(userStorage.get());
    const { language } = data?.data;
    userStorage.set(JSON.stringify({ ...getCurrentUser, ...data?.data }));
    if (_.get(getCurrentUser, 'language') !== language) {
        const subString = String(langCodes[(data?.data)]);
        const lazyLang = React.lazy(() => import(`moment/locale/${language}`));
        const locales = language !== 'en' ? lazyLang : null;
        yield i18n.changeLanguage(language);
        if (locales) {
            yield moment.updateLocale(subString, locales);
        }
    }
}

export function* watchGetUserList({ data }) {
    try {
        const response = yield call(getUsersAPI, data);
        if (response && response.status === 200) {
            yield put(getUserList.success(response.data.items));
        }
    } catch (error) {
        message.error(window.t('Something went wrong'));
        yield put(getUserList.failure(window.t(exception['500'])));
    }
}
