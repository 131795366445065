import { call, put, takeLatest, all } from 'redux-saga/effects';
import _ from 'lodash';
import {
    getTimeMarkCategoriesApi,
    getTimeMarksByCategoryApi,
    createTimeMarkApi,
    deleteTimeMarkApi,
    updateTimeMarkApi,
    getTimeMarkIconApi,
    getAllTimeMarksApi,
} from 'services/timeMarkApis';
import {
    getTimeMarkCategories,
    getTimeMarksByCategory,
    createTimeMarkAction,
    deleteTimeMarkAction,
    updateTimeMarkAction,
    getTimeMarkIcon,
    getAllTimeMarks,
} from './timeMarkAction';
import { exception } from 'constants/errorCode';

export default function* TimeMarkSagas() {
    yield all([
        takeLatest(getTimeMarkCategories.REQUEST, watchGetTimeMarkCategories),
        takeLatest(getTimeMarksByCategory.REQUEST, watchGetTimeMarksByCategory),
        takeLatest(createTimeMarkAction.REQUEST, watchCreateTimeMarkAction),
        takeLatest(deleteTimeMarkAction.REQUEST, watchDeleteTimeMarkAction),
        takeLatest(updateTimeMarkAction.REQUEST, watchUpdateTimeMarkAction),
        takeLatest(getTimeMarkIcon.REQUEST, watchGetTimeMarkIcons),
        takeLatest(getAllTimeMarks.REQUEST, watchGetAllTimeMarks),
    ]);
}

export function* watchCreateTimeMarkAction(payload) {
    try {
        // yield delay(300);
        const data = _.omit(payload?.data, ['selectedCategoryId']);
        const response = yield call(createTimeMarkApi, data);
        if (response && response.status === 200) {
            const id = _.get(response, 'data.data.id');
            const timeMark = { ...payload?.data, id };
            yield put(createTimeMarkAction.success(timeMark));
            yield put(getAllTimeMarks.request());
        } else {
            throw response;
        }
    } catch (error) {
        yield put(createTimeMarkAction.failure(window.t(exception['500'])));
    }
}

export function* watchDeleteTimeMarkAction(payload) {
    try {
        // yield delay(300);
        const response = yield call(deleteTimeMarkApi, payload?.data);
        if (response && response.status === 200) {
            yield put(deleteTimeMarkAction.success(payload?.data));
            yield put(getAllTimeMarks.request());
        } else {
            throw response;
        }
    } catch (error) {
        yield put(deleteTimeMarkAction.failure(window.t(exception['500'])));
    }
}

export function* watchUpdateTimeMarkAction(payload) {
    try {
        // yield delay(300);
        const data = _.omit(payload?.data, ['selectedCategoryId']);
        const response = yield call(updateTimeMarkApi, data);
        if (response && response.status === 200) {
            yield put(updateTimeMarkAction.success(payload?.data));
            yield put(getAllTimeMarks.request());
        } else {
            throw response;
        }
    } catch (error) {
        yield put(updateTimeMarkAction.failure(window.t(exception['500'])));
    }
}

export function* watchGetTimeMarkCategories() {
    try {
        // yield delay(300);
        const response = yield call(getTimeMarkCategoriesApi);
        if (response && response.status === 200) {
            yield put(getTimeMarkCategories.success(response.data.data));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(getTimeMarkCategories.failure(window.t(exception['500'])));
    }
}

export function* watchGetAllTimeMarks(payload) {
    try {
        // yield delay(300);
        const response = yield call(getAllTimeMarksApi, payload?.data);
        if (response && response.status === 200) {
            yield put(getAllTimeMarks.success(response.data.data));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(getAllTimeMarks.failure(window.t(exception['500'])));
    }
}

export function* watchGetTimeMarksByCategory(payload) {
    try {
        // yield delay(300);
        const response = yield call(getTimeMarksByCategoryApi, payload?.data);
        if (response && response.status === 200) {
            yield put(getTimeMarksByCategory.success(response.data.data));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(getTimeMarksByCategory.failure(window.t(exception['500'])));
    }
}

export function* watchGetTimeMarkIcons(payload) {
    try {
        // yield delay(300);
        const response = yield call(getTimeMarkIconApi, payload?.data);
        if (response && response.status === 200) {
            yield put(getTimeMarkIcon.success(response.data.data));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(getTimeMarkIcon.failure(window.t(exception['500'])));
    }
}
