export const RISK_MITIGATION_PRIORITY_CODE = {
    NORMAL: 'N',
    IMPORTANT: 'I',
    CRITICAL: 'C',
};

export const CAUSAL_RISK_FACTOR_CATEGORY_CODE = {
    ERGONOMIC: 'E',
    STRUCK_BY: 'SB',
    SLIP_TRIP_FALL: 'STF',
    CAUGHT_IN_BETWEEN: 'CIB',
    SUPPLEMENTAL_ASSESSMENT: 'SA',
};

export const MAX_COUNT_ITEMS = 100;
export const MAX_COUNT_ITEMS_TEXT = '99+';
