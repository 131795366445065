// COPYRIGHT (C) [2023], CompScience, Inc.
// This software is proprietary and confidential. Unauthorized copying,
// distribution, modification, or use is strictly prohibited. This software is provided
// "as is," without warranty of any kind.

import Keycloak from 'keycloak-js';
import { getLang } from '../common/extensions';
import { MESSAGE_DELAY_IN_MILLISECOND } from 'constants/index';
import _ from 'lodash';
import queryString from 'query-string';
import { getPageQuery } from 'utils';
import tokenStorage from 'storage/TokenStorage';
import refreshTokenStorage from 'storage/RefreshTokenStorage';
import currentClientStorage from 'storage/CurrentClientStorage';
import userStorage from 'storage/UserStorage';

export const { REACT_APP_AUTH_SERVICE_BASE_URL, REACT_APP_AUTH_SERVICE_CLIENT_ID, REACT_APP_AUTH_SERVICE_REALM } = process.env;

function createKeycloakInstance() {
    return Keycloak({
        url: REACT_APP_AUTH_SERVICE_BASE_URL,
        realm: REACT_APP_AUTH_SERVICE_REALM,
        clientId: REACT_APP_AUTH_SERVICE_CLIENT_ID,
    });
}

export function genIdPLogoutUrl(redirectPath) {
    const webBaseUrl = window.location.origin;
    const redirectUrl = `${webBaseUrl}${redirectPath}`;
    return `${REACT_APP_AUTH_SERVICE_BASE_URL}/realms/${REACT_APP_AUTH_SERVICE_REALM}/protocol/openid-connect/logout?redirect_uri=${encodeURIComponent(
        redirectUrl
    )}`;
}

export async function obtainKeycloakSessionToken(redirectUri = window.location, loginHint = undefined, prompt = undefined) {
    const keycloakInstance = createKeycloakInstance();
    let keycloakLogin = keycloakInstance.login;
    const language = getLang();
    keycloakInstance.login = options => {
        if (options) {
            options.redirectUri = redirectUri;
            options.locale = language;
            options.loginHint = loginHint;
            options.prompt = prompt;
            keycloakLogin(options);
        }
    };

    await keycloakInstance.init({ onLoad: 'login-required', checkLoginIframe: false });

    tokenStorage.set(keycloakInstance.token);
    refreshTokenStorage.set(keycloakInstance.refreshToken);

    return keycloakInstance.token;
}

/**
 * Clear storage and redirect to keycloak login screen when token expired or invalid
 * Debounce to avoid multiple call
 * @param  null
 * @return redirect to keycloak login screen
 */
export const reLoginDebounce = _.debounce(ReLogin, MESSAGE_DELAY_IN_MILLISECOND, {
    leading: true,
    trailing: false,
});

async function ReLogin() {
    const language = getLang();
    tokenStorage.del();
    refreshTokenStorage.del();
    currentClientStorage.del();
    userStorage.set(JSON.stringify({ language }));

    const pageParams = getPageQuery();
    const loginHint = pageParams?.login_hint?.toString();
    let prompt = pageParams?.prompt?.toString();

    let redirectUri = window.location.href;
    if (loginHint || prompt) {
        // Forward OIDC login parameters: login_hint & prompt
        // For the parameter details, please refer: https://openid.net/specs/openid-connect-core-1_0.html#AuthRequest
        delete pageParams.loginHint;
        delete pageParams.prompt;
        redirectUri = `${window.location.origin}?${queryString.stringify(pageParams)}`;
    }

    return await obtainKeycloakSessionToken(redirectUri, loginHint, prompt);
}

export async function accountRefreshToken() {
    const url = `${REACT_APP_AUTH_SERVICE_BASE_URL}/realms/${REACT_APP_AUTH_SERVICE_REALM}/protocol/openid-connect/token`;

    const client = JSON.parse(currentClientStorage.get());

    const response = await fetch(url, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'x-client-id': client?.id,
        },
        method: 'POST',
        body: new URLSearchParams({
            client_id: REACT_APP_AUTH_SERVICE_CLIENT_ID,
            grant_type: 'refresh_token',
            refresh_token: refreshTokenStorage.get(),
        }),
    });

    const isSuccess = (response.status >= 200 && response.status < 300) || response.status === 304;
    if (isSuccess && !response?.data?.error) {
        const json = await response.clone().json();

        if (!json.error) {
            tokenStorage.set(json.access_token);
            refreshTokenStorage.set(json.refresh_token);
            return json.access_token;
        } else {
            reLoginDebounce();
        }
    } else {
        reLoginDebounce();
    }
}
