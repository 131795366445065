export const types = {
    CUSTOM_RISK_ANALYTIC: 'CUSTOM_RISK_ANALYTIC',
    FETCH_RISK_ANALYTIC: 'FETCH_RISK_ANALYTIC',
    RESET_RISK_ANALYTIC: 'RESET_RISK_ANALYTIC',
    SWITCH_CHART_RISK_ANALYTIC: 'SWITCH_CHART_RISK_ANALYTIC',
    SET_IS_READY_CHART: 'SET_IS_READY_CHART',
};

export const customRiskAnalytic = data => ({
    type: types.CUSTOM_RISK_ANALYTIC,
    data,
});

export const fetchRiskAnalytic = data => {
    return dispatch =>
        dispatch({
            type: types.FETCH_RISK_ANALYTIC,
            data,
        });
};

export const resetRiskAnalytic = data => {
    return dispatch =>
        dispatch({
            type: types.RESET_RISK_ANALYTIC,
            data,
        });
};

export const switchChartRiskAnalytic = data => {
    return dispatch =>
        dispatch({
            type: types.SWITCH_CHART_RISK_ANALYTIC,
            data,
        });
};

export const setIsReadyChart = data => {
    return dispatch => dispatch({ type: types.SET_IS_READY_CHART, data });
};
